import React, { useEffect } from 'react';
import StartPageTemplate from '../../common/start-page-template/StartPageTemplate';
import PassConfirmationForm from './PassConfirmationForm';
import Button from '@mui/material/Button';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { useTranslation } from 'react-i18next';
import FeaturesContentLogIn from '../login/FeaturesContentLogIn';
import DemoAuthTemplate from '../../common/demo-auth-template/DemoAuthTemplate';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { switchDesign } from '../../common/design-switch/designSwitchSlice';

const PassConfirmation = () => {
  const location = useLocation();
  const invitationURL = location.pathname.includes('join_company');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(switchDesign(true));
  }, []);
  return (
    <DemoAuthTemplate>
      {/*<ClerkLogIn />*/}
      <PassConfirmationForm />
    </DemoAuthTemplate>
  );
};

export default PassConfirmation;
