import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './AiSummaryDetails.module.scss';
import AISummaryIcon from '../../../../../static/assets/svg/list_alt_check.svg';
import FileSaveIcon from '../../../../../static/assets/svg/file_save.svg';
import EditContractIcon from '../../../../../static/assets/svg/contract_edit.svg';
import BrushIcon from '../../../../../static/assets/svg/brush.svg';

const AiSummaryDetails: React.FC = () => (
  <Box className={styles.rightSection}>
    <Box className={styles.aiSummaryHeader}>
      <Typography className={styles.aiSummaryTitle}>AI document summary</Typography>
      <Box className={styles.headerActions}>
        <IconButton size='small' className={styles.actionButton}>
          <AISummaryIcon />
        </IconButton>
        <IconButton size='small' className={styles.actionButton}>
          <FileSaveIcon />
        </IconButton>
        <IconButton size='small' className={styles.actionButton}>
          <EditContractIcon />
        </IconButton>
        <IconButton size='small' className={styles.actionButton}>
          <BrushIcon />
        </IconButton>
        <IconButton size='small' className={styles.actionButton}>
          <MoreVertIcon fontSize='small' />
        </IconButton>
      </Box>
    </Box>
    <Box className={styles.aiSummaryContent}>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table size='small' className={styles.summaryTable} sx={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHeader} width='35%'>
                <strong>Key Information</strong>
              </TableCell>
              <TableCell className={styles.tableHeader} width='65%'>
                <strong>Details</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Document Type</strong>
              </TableCell>
              <TableCell>Contract</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Parties Involved</strong>
              </TableCell>
              <TableCell>
                Employer:{' '}
                <strong>
                  <em>____</em>
                </strong>{' '}
                <br />
                Employee: John Doe
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Job Title</strong>
              </TableCell>
              <TableCell>
                <strong>
                  <em>____</em>
                </strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Employment Type</strong>
              </TableCell>
              <TableCell>Part-time</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Regular Hours of Work</strong>
              </TableCell>
              <TableCell>
                <strong>
                  <em>____</em>
                </strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Additional Hours</strong>
              </TableCell>
              <TableCell>May be worked with mutual agreement.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Hourly Wage</strong>
              </TableCell>
              <TableCell>
                <strong>
                  <em>_</em>
                </strong>
                ___ per hour
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Payment for Additional Hours</strong>
              </TableCell>
              <TableCell>
                Regular rate unless exceeding 8 hours/day, 6 days/week, or 44 hours/week (then
                overtime applies).
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Wage Payment Method</strong>
              </TableCell>
              <TableCell>
                <strong>
                  <em>____</em>
                </strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>VNPF Payment</strong>
              </TableCell>
              <TableCell>In accordance with the VNPF Act.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Sick Leave</strong>
              </TableCell>
              <TableCell>
                No paid sick leave unless &apos;in continuous employment&apos; (22 days/month for 1
                year).
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Annual Leave</strong>
              </TableCell>
              <TableCell>
                No paid annual leave unless &apos;in continuous employment&apos; (22 days/month for
                1 year).
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Severance Allowance</strong>
              </TableCell>
              <TableCell>
                No severance unless &apos;in continuous employment&apos; (4 or more days/week for 1
                year).
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Notice Period for Termination</strong>
              </TableCell>
              <TableCell>2 weeks notice by either party.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Absence Policy</strong>
              </TableCell>
              <TableCell>
                Absence for more than 2 days without contact deemed resignation.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Termination for Misconduct</strong>
              </TableCell>
              <TableCell>
                Immediate termination for serious misconduct after giving the employee a chance to
                respond.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.keyCell}>
                <strong>Date of Document</strong>
              </TableCell>
              <TableCell>30 April 2014</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Box>
);

export default AiSummaryDetails;
