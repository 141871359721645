import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import DetailsCard from '../../../common/details-card/DetailsCard';
import OrganizationInfoCard from '../../../common/organization-info-card/OrganizationInfoCard';

import DocumentList from '../../../common/document-list/DocumentList';
import styles from './AllTabs.module.scss';
import { Company } from '../../../models/company.model';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { fetchDocuments } from '../../../slices/documents/documentsSlice';
import { fetchDocumentContracts } from '../../../slices/document-contracts/documentContractsSlice';
import DocumentListVault from '../../document-vault/components/document-list-vault/DocumentListVault';

interface AllTabsProps {
  company: Company;
  documents: any[];
  key: string;
}

const AllTabs: React.FC<AllTabsProps> = ({ company, documents }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // const fetchAllDocuments = async () => {
  //   const results = await Promise.all(
  //     company.members.map(async (member) => {
  //       const [docsAction, contractsAction] = await Promise.all([
  //         dispatch(fetchDocuments({ data: { user: member.id, category: 'business' } })),
  //         dispatch(fetchDocumentContracts({ data: { user: member.id, category: 'business' } })),
  //       ]);
  //       return [
  //         ...(Array.isArray(docsAction.payload) ? docsAction.payload : []),
  //         ...(Array.isArray(contractsAction.payload) ? contractsAction.payload : []),
  //       ];
  //     })
  //   );
  //   // Flatten the result and update the documents state
  //   setDocuments(results.flat());
  // };
  // useEffect(() => {
  //   fetchAllDocuments();
  // }, []);

  return (
    <Grid container rowGap={1}>
      <Grid container spacing={1} className={styles.equal_height_container}>
        <Grid item xs={12} sm={12} md={6} className={styles.equal_height_item}>
          <DetailsCard simplified company={company} />
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={styles.equal_height_item}>
          <OrganizationInfoCard />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <DocumentListVault documents={documents} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AllTabs;
