import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import {
  fetchBusinessProfileNotes,
  selectBusinessProfileNotes,
  selectBusinessProfileStatus,
  clearBusinessProfileNotes,
} from '../../slices/business-profile/businessProfileSlice';
import styles from './NotesTab.module.scss';
import NotesCard from './NotesCard';
import CreateNoteModal from '../create-note-modal/CreateNoteModal';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NoteItem from '../list-item-note/NoteItem';
import {
  fetchEmployeeNotes,
  selectEmployeeById,
  selectEmployeeNotes,
  clearEmployeeNotes,
} from '../../slices/employees/employeesSlice';

interface NotesTabsProps {
  companyId?: number;
  employee?: any;
  key: string;
}

const NotesTab: React.FC<NotesTabsProps> = ({ employee, companyId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const businessProfileNotes = useAppSelector(selectBusinessProfileNotes);
  const employeeNotes = useAppSelector(selectEmployeeNotes);
  const businessProfileStatus = useAppSelector(selectBusinessProfileStatus);
  const isLoading = businessProfileStatus === 'loading';
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchBusinessProfileNotes({ companyId }));
    }
    return () => {
      dispatch(clearBusinessProfileNotes()); // Clear business profile notes on unmount
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    if (employee?.id !== undefined) {
      dispatch(fetchEmployeeNotes(employee.id));
    }
    return () => {
      dispatch(clearEmployeeNotes()); // Clear employee notes on unmount
    };
  }, [dispatch, employee]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toogleShowAddlModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }} variant='h6'>
          {t('businessProfile.notesTab')}
        </Typography>
        <Grid container alignItems='center'>
          <Grid item>
            <IconButton onClick={toogleShowAddlModal}>
              <AddIcon sx={{ marginBottom: '0.15rem', color: '#667085' }} />
            </IconButton>
          </Grid>
        </Grid>
        {/* <Card>
          <CardContent className={isLoading ? styles.card_content_min_height : ''}> */}
        <Grid container>
          {companyId !== undefined &&
            [...businessProfileNotes]
              .sort((a: any, b: any) => a.id - b.id) // Sort by note.id in ascending order
              .map((note: any) => (
                <Grid className={styles.card_border_bottom} item xs={12} key={note.id}>
                  <NotesCard note={note} />
                  {/* <NoteItem text={note.content} date={note.date} name={note.author} /> */}
                </Grid>
              ))}

          {employee?.id !== undefined &&
            [...employeeNotes]
              .sort((a: any, b: any) => a.id - b.id) // Sort by empnote.id in ascending order
              .map((empnote: any) => (
                <Grid className={styles.card_border_bottom} item xs={12} key={empnote.id}>
                  <NotesCard empnote={empnote} />
                  {/* <NoteItem text={note.content} date={note.date} name={note.author} /> */}
                </Grid>
              ))}
        </Grid>
        {/* </CardContent>
        </Card> */}
      </Grid>
      {isModalOpen && (
        <Box ref={modalRef}>
          <CreateNoteModal
            companyId={companyId}
            employeeId={employee?.id}
            open={isModalOpen}
            onClose={() => toogleShowAddlModal()}
          />
        </Box>
      )}
    </Grid>
  );
};

export default NotesTab;
