import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Brand from '../../../static/assets/svg/brand.svg';
import BrandName from '../../../static/assets/svg/brand-name.svg';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { ROUTES } from '../../common/constants/routes';
import { useNavigate } from 'react-router-dom';
import { NWClient } from '../../client/NWClient';
import { toast } from 'react-toastify';
import { FormControl, FormHelperText } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { Company } from '../../models/company.model';
import { switchDesign } from '../../common/design-switch/designSwitchSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CountryItem } from '../../common/company-create-form/types';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectCountriesInfo } from '../../slices/countries/countriesSlice';

export const CompanyCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onlyDigitSpaceRegExp = useMemo(() => {
    return /^[0-9 ]*$/;
  }, []);
  const phoneRegExp = useMemo(() => {
    return /^\+?[\s\d]*$/;
    //return /^\+\d*$/;
  }, []);
  const [searchParams] = useSearchParams();
  const fromPricingPage = searchParams.get('fromPricingPage');
  const redirectToStripe = searchParams.get('redirectToStripe');
  const productId = searchParams.get('productId');
  const priceId = searchParams.get('priceId');
  const backUrl = searchParams.get('backUrl');
  const clientEmail = searchParams.get('prefilled_email');
  const clientUUID = searchParams.get('client_reference_id');
  const urlParams = `?productId=${productId}&priceId=${priceId}`;
  const urlParamsForStripe = `?prefilled_email=${clientEmail}&client_reference_id=${clientUUID}`;
  const [businessName, setBusinessName] = useState('');
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState('');
  const [businessABNorACN, setBusinessABNorACN] = useState('');
  const [amountOfEmployers, setAmountOfEmployers] = useState('');
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [country, setCountry] = useState<{ value: string; title: string } | null>(null);
  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectCountriesInfo);
  const countriesOptions = [...countries]
    .filter((el) => el.code !== 'GB')
    .map((el) => ({ value: el.name, title: el.name }));

  const handleInputsPossibleErrors = (propertyName: string) => {
    if (fieldsErrors && fieldsErrors[propertyName]) {
      const obj = { ...fieldsErrors };
      delete obj[propertyName];
      setFieldsErrors(obj);
    }
  };

  const handleBusinessABNorACNChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBusinessABNorACN(e.target.value);
    handleInputsPossibleErrors('business_abn_acn');
  };

  const handleBusinessPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBusinessPhoneNumber(e.target.value);
    handleInputsPossibleErrors('phone_number');
  };

  const basicContainerStyles = { margin: '0 auto' };

  const canSubmit = Boolean(businessName) && Boolean(country);

  useEffect(() => {
    dispatch(switchDesign(true));
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule('ABNorACNLength', (value) => {
      return (
        onlyDigitSpaceRegExp.test(value.trim()) &&
        (value.trim().replace(/\s/g, '').length === 11 ||
          value.trim().replace(/\s/g, '').length === 9 ||
          value.trim().length === 0)
      );
    });
    return () => {
      ValidatorForm.removeValidationRule('ABNorACNLength');
    };
  }, [businessABNorACN, onlyDigitSpaceRegExp]);

  useEffect(() => {
    ValidatorForm.addValidationRule('noNegative', (value) => {
      return value >= 0;
    });

    return () => {
      ValidatorForm.removeValidationRule('noNegative');
    };
  }, [amountOfEmployers]);

  useEffect(() => {
    ValidatorForm.addValidationRule('phone', (value) => {
      return (
        (phoneRegExp.test(value.trim()) || value === '') &&
        value.trim().replace(/\s/g, '').length < 18
      );
    });

    return () => {
      ValidatorForm.removeValidationRule('phone');
    };
  }, [businessPhoneNumber, phoneRegExp]);

  useEffect(() => {
    ValidatorForm.addValidationRule('valueLength255', (value) => {
      return value.trim().length < 256;
    });

    return () => {
      ValidatorForm.removeValidationRule('valueLength255');
    };
  }, [businessABNorACN]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const data = {
      name: businessName,
      phone_number: businessPhoneNumber.trim().replace(/\s/g, ''),
      business_abn_acn: businessABNorACN.trim().replace(/\s/g, ''),
      country: country ? country.value : null,
      postal_country: country ? country.value : null,
      physical_country: country ? country.value : null,
    };

    NWClient.post('company', data)
      .then((res: Company) => {
        const id = Number(res.id);
        NWClient.post(`business-profile-details/${id}/`, { company: id }, false).then(() => {
          NWClient.post(
            `business-profile-bank-details/${id}/`,
            { company: id, currency: 'CAD' },
            false
          );
        });
        toast.success(t('messages.companyCreated'), { theme: 'colored' });
        if (fromPricingPage === 'true') {
          navigate(ROUTES.PRODUCT_PLAN_CONFIRMATION + urlParams);
        } else if (backUrl) {
          navigate(backUrl);
        } else if (redirectToStripe === 'true') {
          navigate(ROUTES.PRICING);
          /*window.location.href =
              (!process.env.ENV_TYPE || process.env.ENV_TYPE !== 'PROD'
                ? ROUTES.STRIPE_PAYMENT_LINK
                : ROUTES.STRIPE_PAYMENT_LINK_PROD) + urlParamsForStripe;*/
        } else {
          navigate(ROUTES.INDEX);
        }
      })
      .catch((error) => {
        setFieldsErrors(error.response.data);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  return (
    <>
      <div className='company-create-page'>
        <Grid container maxWidth='xl' style={{ ...basicContainerStyles }}>
          <header>
            <Grid item xs={12}>
              <div className='logo-container' role='img' aria-label='Logo'>
                <Brand />
                <BrandName />
              </div>
            </Grid>
          </header>
        </Grid>
        <div className='company-create-container'>
          <h1 className='text-center text-transform-none'>{t('messages.enterBusinessDetails')}</h1>
          <ValidatorForm onSubmit={handleSubmit} className='company-data-form'>
            <div>
              <TextValidator
                label={t('labels.businessOrCompany')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setBusinessName(e.target.value)}
                name='businessName'
                value={businessName}
                required={true}
                validators={['required', 'valueLength255']}
                errorMessages={[t('messages.fieldRequired'), t('messages.maxLength255')]}
              />
            </div>
            <div>
              <FormControl className='w-100'>
                <Autocomplete
                  className='w-100'
                  id='country'
                  openOnFocus={true}
                  options={countriesOptions}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  getOptionLabel={(option) => option.title}
                  value={country}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                    newValue: {
                      title: string;
                      value: string;
                    } | null
                  ) => {
                    setCountry(newValue);
                  }}
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li key={option.value} {...optionProps}>
                        {option.title}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      required={true}
                      label={t('labels.country')}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div>
              <TextValidator
                label={t('labels.businessPhoneNumber')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleBusinessPhoneNumberChange(e)}
                name='businessPhoneNumber'
                validators={['phone']}
                errorMessages={[t('messages.enterCorrectPhoneNumber')]}
                value={businessPhoneNumber}
              />
              {Boolean(fieldsErrors?.phone_number) && (
                <FormHelperText error={true}>{fieldsErrors?.phone_number.join('.')}</FormHelperText>
              )}
            </div>
            <div>
              <TextValidator
                label={
                  <>
                    <span className='me-1'>{t('labels.ABNorACN')}</span>
                    <Tooltip
                      className='flex-shrink-0'
                      title={t('labels.registrationNumberTypes')}
                      placement='top'
                      arrow={true}
                    >
                      <InfoIcon fontSize='small' color='secondary' />
                    </Tooltip>
                  </>
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleBusinessABNorACNChange(e)}
                name='businessABNorACN'
                validators={['ABNorACNLength']}
                value={businessABNorACN}
                errorMessages={[t('messages.ABNandACNMessage')]}
              />
              {Boolean(fieldsErrors?.business_abn_acn) && (
                <FormHelperText error={true}>
                  {fieldsErrors?.business_abn_acn.join('.')}
                </FormHelperText>
              )}
            </div>
            <div className='buttons-row'>
              <Button
                type='submit'
                variant='contained'
                className='btn-action-basic btn-default'
                disabled={!canSubmit}
              >
                {t('buttons.next')}
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </div>
    </>
  );
};
