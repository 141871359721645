import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { NWClient } from '../../../client/NWClient';
import { StyledTableCell } from '../../category/components/TemplatesTable/components/TableCell';
import { useTranslation } from 'react-i18next';
import DialogItem from '../../../common/dialog-item/DialogItem';
import { Link, useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants/routes';
import { deleteDocument, selectAllDocuments } from '../../../slices/documents/documentsSlice';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ProgressOverlay } from '../../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { selectUser } from '../../../slices/user/userSlice';
import { DocumentSigningModel } from '../../../models/document.signing.model';
import AIIcon from '../../../../static/assets/svg/ai-icon.svg';
import Tooltip from '@mui/material/Tooltip';
import DocumentSigningIcon from '../../../../static/assets/svg/menu/documents-signing.svg';
import DownloadIcon from '../../../../static/assets/svg/download-icon.svg';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import PacmanLoader from 'react-spinners/PacmanLoader';
import GenerateTemplateIcon from '../../../../static/assets/svg/generate-template-icon.svg';
import { deleteDocumentTemplate } from '../../../slices/document-templates/documentTemplatesSlice';
import {
  deleteDocumentContract,
  selectAllDocumentContracts,
} from '../../../slices/document-contracts/documentContractsSlice';
import AISummaryDialog from '../../../common/ai-summary-dialog/AISummaryDialog';
import PlanUpgradingMessage from '../../../common/plan-upgrading-message/PlanUpgradingMessage';
import { DocumentVaultItemModel } from '../../../models/document.vault.item.model';
import { DocumentHtmlModel } from '../../../models/document.html.model';

const icon = <CheckBoxOutlineBlankIcon fontSize='medium' />;
const checkedIcon = <CheckBoxIcon fontSize='medium' />;

export type ActionsCellProps = {
  row: {
    id: number;
    name?: string;
    contract_generation?: number;
  };
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  checked?: boolean;
};

export const ActionsCell = ({ row, align = 'left', checked }: ActionsCellProps) => {
  const { documentId, category } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUser);
  const location = useLocation();
  const [params] = useSearchParams();
  const contractGenerated = params.get('contractGenerated');
  const document = useAppSelector(selectAllDocuments).find((el) => el.id === Number(row.id));
  const documentContract = useAppSelector(selectAllDocumentContracts).find(
    (el) => el.id === Number(row.id)
  );
  const purposes = [
    {
      title: t('purpose.verification'),
      value: 'verification',
    },
    {
      title: t('purpose.analysis'),
      value: 'analysis',
    },
    {
      title: t('purpose.recordKeeping'),
      value: 'record keeping',
    },
  ];
  const path = location.pathname;
  const [errorText, setErrorText] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [formatMessage, setFormatMessage] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [deleteDocumentMessageVisible, setDeleteDocumentMessageVisible] = useState(false);
  const [aiDocumentDialogVisible, setAiDocumentDialogVisible] = useState(false);
  const [documentToDeleteId, setDocumentToDeleteId] = useState<null | number>(null);
  const [documentTitle, setDocumentTitle] = useState('');
  const [documentType, setDocumentType] = useState('contract');
  const [purpose, setPurpose] = useState(purposes);
  const [resultFormat, setResultFormat] = useState('table');
  const [detailLevel, setDetailLevel] = useState('high level');
  const [simplifyJargon, setSimplifyJargon] = useState(true);
  const [contextualInformation, setContextualInformation] = useState('');
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);
  const [fileTypeDialogVisible, setFileTypeDialogVisible] = useState<boolean>(false);
  const [documentContractToDownloadId, setDocumentContractToDownloadId] = useState<null | number>(
    null
  );
  const [fileTypeForDownloading, setFileTypeForDownloading] = useState<'pdf' | 'doc'>('pdf');
  const [fileNameForDownloading, setFileNameForDownloading] = useState<string>('');
  const [planUpgradingMessage, setPlanUpgradingMessage] = useState<boolean>(false);

  const errorMessageTitle = t('messages.errorOccurred');
  const NoPermissionMessage = () => {
    return (
      <>
        {t('messages.startSubscription')}&nbsp;<Link to={ROUTES.PRICING}>{t('links.here')}</Link>.
      </>
    );
  };
  const noPermissionTitle = t('messages.downloadMessageTitle');

  const handleDownloadLink = (id: number) => {
    if (row.contract_generation || contractGenerated) {
      setDocumentContractToDownloadId(id);
      setFileTypeDialogVisible(true);
    } else if (typeof checked !== 'undefined' && checked) {
      setFileTypeDialogVisible(true);
    } else {
      setPendingStatus(true);
      NWClient.downloadDocument(id, row.name)
        .catch(() => {
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          setPendingStatus(false);
        });
    }
  };

  const handleDownloadContract = (id: number) => {
    setPendingStatus(true);
    NWClient.downloadDocumentContract(
      id,
      fileNameForDownloading + '.' + fileTypeForDownloading,
      fileTypeForDownloading
    )
      .then(() => {
        handleFileTypeDialogClose();
      })
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setPendingStatus(false);
      });
  };

  const handleFileTypeDialogClose = () => {
    setFileTypeDialogVisible(false);
    setDocumentContractToDownloadId(null);
    setFileNameForDownloading(row.name.split('.').slice(0, -1).join('.'));
    setFileTypeForDownloading('pdf');
  };

  const deleteFileClearingData = () => {
    setDeleting(false);
    setPendingStatus(false);
    setDocumentToDeleteId(null);
  };

  const handleDelete = (id: number) => {
    setDeleting(true);
    setDeleteDocumentMessageVisible(false);
    setPendingStatus(true);
    if (category === 'company-templates') {
      dispatch(deleteDocumentTemplate({ id, userId: currentUser.id }))
        .unwrap()
        .catch((error) => {
          toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          deleteFileClearingData();
        });
    } else if (row.contract_generation) {
      dispatch(deleteDocumentContract({ id, userId: currentUser.id }))
        .unwrap()
        .catch((error) => {
          toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          deleteFileClearingData();
        });
    } else {
      dispatch(deleteDocument({ id, userId: currentUser.id }))
        .unwrap()
        .catch((error) => {
          toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          deleteFileClearingData();
        });
    }
  };

  // TODO : Refactor handleSign whenever possible

  const handleSign = (document_id: number, documentFormat: string) => {
    setPendingStatus(true);
    if (!row.contract_generation || !contractGenerated) {
      if (typeof checked !== 'undefined' && checked) {
        NWClient.get('document-upload', document_id).then((res: DocumentVaultItemModel) => {
          NWClient.get('document-upload-html', Math.max(...res.document_upload_html)).then(
            (res: DocumentHtmlModel) => {
              const data = {
                user: currentUser.id,
                document_upload_html: res.id,
              };
              NWClient.post('document-sign', data, true)
                .then((res: DocumentSigningModel) => {
                  navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
                })
                .catch((error) => {
                  if (
                    error.response?.data?.errors?.length > 0 &&
                    error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
                  ) {
                    setPlanUpgradingMessage(true);
                  } else if (
                    error.response?.data?.errors?.length > 0 &&
                    error.response?.data?.errors[0] === t('messages.userNotAssociatedWithCompany')
                  ) {
                    navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${path}`);
                  } else {
                    toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                      theme: 'colored',
                    });
                  }
                })
                .finally(() => {
                  setPendingStatus(false);
                  return;
                });
            }
          );
        });
      } else {
        NWClient.get('document-upload', document_id).then((res: DocumentVaultItemModel) => {
          NWClient.get('document-upload-html', Math.min(...res.document_upload_html)).then(
            (res: DocumentHtmlModel) => {
              const data = {
                user: currentUser.id,
                document_upload_html: res.id,
              };
              NWClient.post('document-sign', data, true)
                .then((res: DocumentSigningModel) => {
                  navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
                })
                .catch((error) => {
                  if (
                    error.response?.data?.errors?.length > 0 &&
                    error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
                  ) {
                    setPlanUpgradingMessage(true);
                  } else if (
                    error.response?.data?.errors?.length > 0 &&
                    error.response?.data?.errors[0] === t('messages.userNotAssociatedWithCompany')
                  ) {
                    navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${path}`);
                  } else {
                    toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                      theme: 'colored',
                    });
                  }
                })
                .finally(() => {
                  setPendingStatus(false);
                  return;
                });
            }
          );
        });
      }
    } else {
      if (row.contract_generation || contractGenerated) {
        const data = {
          user: currentUser.id,
          [row.contract_generation || contractGenerated ? 'document_contract' : 'document_upload']:
            document_id,
          //signing_doc_id: 1, //should be optional later, to refactor
        };
        NWClient.post('document-sign', data, true)
          .then((res: DocumentSigningModel) => {
            navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
          })
          .catch((error) => {
            if (
              error.response?.data?.errors?.length > 0 &&
              error.response?.data?.errors[0] === t('messages.exceededPlanLimits')
            ) {
              setPlanUpgradingMessage(true);
            } else if (
              error.response?.data?.errors?.length > 0 &&
              error.response?.data?.errors[0] === t('messages.userNotAssociatedWithCompany')
            ) {
              navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${path}`);
            } else {
              toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                theme: 'colored',
              });
            }
          })
          .finally(() => {
            setPendingStatus(false);
          });
      } else {
        setFormatMessage(true);
      }
    }
  };

  const handleAnalyzeSuccess = () => {
    if (documentId) {
      window.location.href = `${path}?initialTab=0${
        contractGenerated ? '&contractGenerated=true' : ''
      }`;
    } else {
      navigate(
        `${path}/${row.id}?initialTab=0${row.contract_generation ? '&contractGenerated=true' : ''}`
      );
    }
  };

  const MenuBlock = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div className='d-inline-flex'>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem style={{ padding: 0 }}>
            <Button
              style={{ width: '100%', borderRadius: 0 }}
              onClick={() => {
                navigate(
                  `${path}${documentId ? '' : '/' + row.id}?initialTab=1${
                    row.contract_generation ? '&contractGenerated=true' : ''
                  }`
                );
              }}
              className='templates-table-action'
              aria-label={`edit ${row.name} button`}
              disabled={downloadLoading || deleting}
            >
              {!deleting ? (
                category === 'templates' ? (
                  t('buttons.view')
                ) : (
                  t('buttons.edit')
                )
              ) : (
                <CircularProgress size={20} />
              )}
            </Button>
          </MenuItem>
          {(document || documentContract) &&
            (document?.open_ai_assist_document_upload?.length > 0 ||
              documentContract?.open_ai_assist_document_contract?.length > 0) && (
              <MenuItem style={{ padding: 0 }}>
                <Button
                  style={{ width: '100%', borderRadius: 0 }}
                  onClick={() => {
                    navigate(
                      `${path}${documentId ? '' : '/' + row.id}?initialTab=0${
                        row.contract_generation ? '&contractGenerated=true' : ''
                      }`
                    );
                  }}
                  className='templates-table-action'
                  aria-label={`summary ${row.name} button`}
                  disabled={downloadLoading || deleting}
                >
                  {!deleting ? t('buttons.summary') : <CircularProgress size={20} />}
                </Button>
              </MenuItem>
            )}
          {category !== 'templates' && (
            <MenuItem style={{ padding: 0 }}>
              <Button
                style={{ width: '100%', borderRadius: 0 }}
                onClick={() => {
                  navigate(`${path}${documentId ? '' : '/' + row.id}?initialTab=2`);
                }}
                className='templates-table-action'
                aria-label={`versions ${row.name} button`}
                disabled={downloadLoading || deleting}
              >
                {!deleting ? t('buttons.versions') : <CircularProgress size={20} />}
              </Button>
            </MenuItem>
          )}
          {category !== 'templates' && (
            <MenuItem style={{ padding: 0 }}>
              <Button
                style={{ width: '100%', borderRadius: 0 }}
                onClick={() => {
                  navigate(`${path}${documentId ? '' : '/' + row.id}?initialTab=3`);
                }}
                className='templates-table-action'
                aria-label={`connections ${row.name} button`}
                disabled={downloadLoading || deleting}
              >
                {!deleting ? t('documentTabs.labels') : <CircularProgress size={20} />}
              </Button>
            </MenuItem>
          )}
          <MenuItem style={{ padding: 0 }}>
            <Button
              style={{ width: '100%', borderRadius: 0 }}
              onClick={() => {
                setDocumentToDeleteId(row.id);
                setDeleteDocumentMessageVisible(true);
              }}
              className='templates-table-action'
              aria-label={`delete ${row.name} button`}
              disabled={downloadLoading || deleting}
            >
              {!deleting ? t('buttons.delete') : <CircularProgress size={20} />}
            </Button>
          </MenuItem>
        </Menu>
      </div>
    );
  };

  useEffect(() => {
    setFileNameForDownloading(row.name.split('.').slice(0, -1).join('.'));
  }, [row]);

  return (
    <>
      <StyledTableCell align={align}>
        <Grid container justifyContent='flex-end'>
          <Grid
            item
            xs={12}
            className='d-flex align-items-center justify-content-end position-relative'
          >
            {!row.contract_generation && (
              <Tooltip title={t('messages.AITemplateCreatMessage')} placement='top' arrow>
                <IconButton
                  className='table-row-icon-button'
                  onClick={() => {
                    setAnalysisLoading(true);
                    navigate(
                      `${path}${documentId ? '' : '/' + row.id}?initialTab=4${
                        row.contract_generation || contractGenerated
                          ? '&contractGenerated=true'
                          : ''
                      }`
                    );
                  }}
                  disabled={downloadLoading || deleting}
                >
                  <GenerateTemplateIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t('messages.AISummaryMessage')} placement='top' arrow>
              <IconButton
                className='table-row-icon-button'
                onClick={() => {
                  setAiDocumentDialogVisible(true);
                  setDocumentTitle(row.name);
                }}
                disabled={downloadLoading || deleting}
              >
                <AIIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('buttons.download')} placement='top' arrow>
              <IconButton
                className='table-row-icon-button'
                onClick={() => {
                  handleDownloadLink(row.id);
                }}
                disabled={downloadLoading || deleting}
                aria-label={`download ${row.name} button`}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('buttons.sign')} placement='top' arrow>
              <IconButton
                className='table-row-icon-button'
                onClick={() => {
                  handleSign(Number(row.id), row.name.split('.').pop().toLowerCase());
                }}
                aria-label={`sign ${row.name} button`}
                disabled={downloadLoading || deleting}
              >
                <DocumentSigningIcon />
              </IconButton>
            </Tooltip>
            {documentId && (
              <Tooltip
                title={category === 'templates' ? t('buttons.view') : t('buttons.edit')}
                placement='top'
                arrow
              >
                <IconButton
                  className='table-row-icon-button'
                  onClick={() => {
                    navigate(
                      `${path}${documentId ? '' : '/' + row.id}?initialTab=1${
                        contractGenerated ? '&contractGenerated=true' : ''
                      }`
                    );
                  }}
                  aria-label={`edit ${row.name} button`}
                  disabled={downloadLoading}
                >
                  <ModeEditOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            {!documentId && <MenuBlock />}
          </Grid>
        </Grid>
        <DialogItem
          isErrorMessage={false}
          open={formatMessage}
          title={null}
          text={t('messages.pdfMessage')}
          handleClose={() => setFormatMessage(false)}
        />
        <DialogItem
          isErrorMessage={errorState && errorStatus !== 403}
          open={errorState}
          title={errorStatus !== 403 ? errorMessageTitle : noPermissionTitle}
          text={errorText}
          handleClose={() => setErrorState(false)}
        >
          {errorStatus === 403 ? <NoPermissionMessage /> : null}
        </DialogItem>
        {deleteDocumentMessageVisible && (
          <DialogItem
            isErrorMessage={false}
            open={deleteDocumentMessageVisible}
            title={
              deleteDocumentMessageVisible
                ? t('dialogTitles.deleteFile')
                : t('dialogTitles.deleteDefault')
            }
            text={
              deleteDocumentMessageVisible ? t('messages.deleteFile') : t('messages.deleteDefault')
            }
            noDefaultActionsRow={true}
            handleClose={() => {
              setDeleteDocumentMessageVisible(false);
            }}
          >
            <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
              <Button
                onClick={() => handleDelete(documentToDeleteId)}
                style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                type='button'
                role='button'
                variant='outlined'
                size='medium'
              >
                {t('buttons.deleteFile')}
              </Button>
              <Button
                type='button'
                role='button'
                variant='contained'
                size='medium'
                style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                onClick={() => {
                  setDeleteDocumentMessageVisible(false);
                  setDocumentToDeleteId(null);
                }}
              >
                {t('buttons.goBack')}
              </Button>
            </div>
          </DialogItem>
        )}
        {aiDocumentDialogVisible && (
          <AISummaryDialog
            open={aiDocumentDialogVisible}
            documentContractIdCondition={Boolean(row.contract_generation || contractGenerated)}
            title={documentTitle}
            handleClose={() => setAiDocumentDialogVisible(false)}
            handleAnalyzeSuccess={() => handleAnalyzeSuccess()}
            documentId={Number(row.id)}
            setAnalysisLoading={() => setAnalysisLoading(true)}
            handleAnalyzeError={() => {
              setAnalysisLoading(false);
            }}
            handlePlanLimits={() => setPlanUpgradingMessage(true)}
          />
        )}
        {fileTypeDialogVisible && (
          <DialogItem
            isErrorMessage={false}
            open={fileTypeDialogVisible}
            title={t('dialogTitles.chooseFileType')}
            text={t('messages.fileTypeToDownload')}
            noDefaultActionsRow={true}
            handleClose={() => handleFileTypeDialogClose()}
          >
            <Grid container className='form-controls mt-1' spacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor='fileTypeForDownloading'>{t('labels.fileType')}</InputLabel>
                <Select
                  onChange={(e: SelectChangeEvent) => {
                    setFileTypeForDownloading(e.target.value as 'pdf' | 'doc');
                  }}
                  id='fileTypeForDownloading'
                  name='fileTypeForDownloading'
                  value={fileTypeForDownloading}
                  className='w-100'
                >
                  <MenuItem value={'pdf'}>{t('contractGeneratorDialog.formatPDF')}</MenuItem>
                  <MenuItem value={'doc'}>{t('contractGeneratorDialog.formatWord')}</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('labels.fileName')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFileNameForDownloading(e.target.value)
                  }
                  name='fileName'
                  value={fileNameForDownloading}
                  tabIndex={-1}
                />
              </Grid>
            </Grid>
            <div className='buttons-row d-flex flex-wrap justify-content-end mt-4'>
              <Button
                type='button'
                role='button'
                variant='outlined'
                size='medium'
                style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                onClick={() => handleFileTypeDialogClose()}
              >
                {t('buttons.goBack')}
              </Button>
              <Button
                onClick={
                  typeof checked !== 'undefined' && checked
                    ? () =>
                        NWClient.downloadHtmlDocument(
                          Math.max(...document.document_upload_html),
                          fileNameForDownloading + '.' + fileTypeForDownloading,
                          fileTypeForDownloading
                        )
                    : () => handleDownloadContract(documentContractToDownloadId)
                }
                style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                type='button'
                role='button'
                variant='contained'
                size='medium'
              >
                {t('buttons.download')}
              </Button>
            </div>
          </DialogItem>
        )}
        {pendingStatus ? <ProgressOverlay /> : null}
        {analysisLoading ? (
          <div
            className='position-fixed w-100 vh-100 d-flex align-items-center justify-content-center'
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 3000, left: 0, top: 0 }}
          >
            <PacmanLoader loading={analysisLoading} color='#6414DB' />
          </div>
        ) : null}
        {planUpgradingMessage && (
          <PlanUpgradingMessage
            open={planUpgradingMessage}
            text={t('messages.monthLimitReached')}
            handleClose={() => setPlanUpgradingMessage(false)}
          />
        )}
      </StyledTableCell>
    </>
  );
};
