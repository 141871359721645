import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import { BLACK_COLOR, LIGHT_GREY } from '../../common/constants/colors';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { CompanyMember } from '../../models/member.model';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { fetchEmployees, selectAllEmployees } from '../../slices/employees/employeesSlice';
import { EmployeesCreateModal } from '../company-create/components/modal/EmployeesCreateModal';
import Button from '@mui/material/Button';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { Employee } from '../../models/employee.model';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import DialogItem from '../../common/dialog-item/DialogItem';
import { fetchCompanies } from '../../slices/companies/companiesSlice';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';
import styles from './EmployeeProfilesV2.module.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import NoteIcon from '@mui/icons-material/Note';
import AddIcon from '@mui/icons-material/Add';
import FileSave2Icon from '../../../static/assets/svg/file_save_2.svg';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { NWClient } from '../../client/NWClient';
import MessageModal from '../../common/message-modal/MessageModal';
const EmployeeProfilesV2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pendingStatus, setPendingStatus] = useState(true);
  const [isOpenEmployeesCreation, setIsOpenEmployeesCreation] = useState(false);
  const [planMessageVisible, setPlanMessageVisible] = useState(false);
  const [employeesLimitReached, setEmployeesLimitReached] = useState(false);
  const [deleteMessageModalOpen, setDeleteMessageModalOpen] = useState(false);
  const handleClose = () => {
    setIsOpenEmployeesCreation(false);
  };
  const company = useAppSelector(selectAllCompanies)[0];
  const employeesCreatedByAdmin = useAppSelector(selectAllEmployees);
  const employees: CompanyMember[] = company?.members || [];
  const employeesFiltered = employees.filter((el) =>
    el.groups.every((item) => item.name !== 'Company admin')
  );
  const employeesSorted = employeesFiltered.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );

  const [employeeId, setEmployeeId] = useState<number | null>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setEmployeeId(id);
  };
  const handleMoreVertClose = () => {
    setAnchorEl(null);
  };

  const handleProfileDelete = () => {
    if (employeeId) {
      NWClient.delete('employee', employeeId).then(() => {
        setDeleteMessageModalOpen(false);
        setEmployeeId(null);
        dispatch(fetchEmployees({ company_id: company?.id }));
        setAnchorEl(null);
      });
    }
  };

  const handleEditButton = (id: number) => {
    navigate(ROUTES.EMPLOYEE_PROFILES + '/' + id + '?initialTab=1');
  };

  const handleDocumentsButton = (id: number) => {
    navigate(ROUTES.EMPLOYEE_PROFILES + '/' + id + '?initialTab=2');
  };

  const handleCreateEmployee = () => {
    if (!company.stripe_product_id && !company.plan_settings.stripe_product_id) {
      if (
        typeof company.plan_settings.free === 'object' &&
        company.plan_settings.free.employee_profile === 0
      ) {
        setPlanMessageVisible(true);
      } else if (
        typeof company.plan_settings.free === 'object' &&
        employeesCreatedByAdmin?.length === company.plan_settings.free.employee_profile
      ) {
        setPlanMessageVisible(true);
        setEmployeesLimitReached(true);
      } else if (
        typeof company.plan_settings.free === 'object' &&
        employeesCreatedByAdmin?.length < company.plan_settings.free.employee_profile
      ) {
        navigate(ROUTES.EMPLOYEE_PROFILE_CREATE);
      }
    } else if (company.stripe_product_id && !company.plan_settings.stripe_product_id) {
      if (
        typeof company.plan_settings.legacy === 'object' &&
        employeesCreatedByAdmin?.length === company.plan_settings.legacy.employee_profile
      ) {
        setPlanMessageVisible(true);
        setEmployeesLimitReached(true);
      } else if (
        typeof company.plan_settings.legacy === 'object' &&
        employeesCreatedByAdmin?.length < company.plan_settings.legacy.employee_profile
      ) {
        navigate(ROUTES.EMPLOYEE_PROFILE_CREATE);
      }
    } else if (!company.stripe_product_id && company.plan_settings.stripe_product_id) {
      if (
        typeof company.plan_settings.stripe_product_id === 'string' &&
        typeof company.plan_settings[company.plan_settings.stripe_product_id] === 'object' &&
        employeesCreatedByAdmin?.length === // @ts-expect-error: can be error
          company.plan_settings[company.plan_settings.stripe_product_id].employee_profile
      ) {
        setPlanMessageVisible(true);
        setEmployeesLimitReached(true);
      } else {
        if (
          typeof company.plan_settings.stripe_product_id === 'string' &&
          typeof company.plan_settings[company.plan_settings.stripe_product_id] === 'object' &&
          employeesCreatedByAdmin?.length < // @ts-expect-error: can be error
            company.plan_settings[company.plan_settings.stripe_product_id].employee_profile
        ) {
          navigate(ROUTES.EMPLOYEE_PROFILE_CREATE);
        }
      }
    }
  };

  useEffect(() => {
    if (company) {
      dispatch(fetchEmployees({ company_id: company?.id }));
      setPendingStatus(false);
    } else {
      dispatch(fetchCompanies())
        .unwrap()
        .finally(() => setPendingStatus(false));
    }
  }, [company]);

  return (
    <Grid container sx={{ mt: '-70px' }}>
      <Typography variant='h5' component='h1' className={styles.page_title}>
        {t('employeeProfiles.header')}
      </Typography>
      <Grid item xs={12} className={styles.employee_cards}>
        {employeesCreatedByAdmin.map((employee: Employee) => (
          <Card
            tabIndex={-1}
            key={employee.id}
            onClick={() => navigate(ROUTES.EMPLOYEE_PROFILES + '/' + employee.id)}
            className={styles.employee_card}
          >
            {employee.first_name && (
              <Typography fontWeight='regular'>
                {employee.first_name} {employee.last_name}
              </Typography>
            )}
            <Box className={styles.employee_profile_icons}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleDocumentsButton(employee.id);
                }}
                size='small'
              >
                <UploadFileOutlinedIcon fontSize='small' />
              </IconButton>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditButton(employee.id);
                }}
                size='small'
                sx={{ p: 0.5 }}
              >
                <EditIcon fontSize='small' />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(event, employee.id);
                }}
                size='small'
                sx={{ p: 0.5 }}
              >
                <MoreVertIcon fontSize='small' />
              </IconButton>
            </Box>
          </Card>
        ))}
        <Button
          onClick={handleCreateEmployee}
          variant='contained'
          color='primary'
          className={styles.add_button}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}>
            <Typography fontWeight='light' fontSize={14}>
              {t('buttons.addProfile')}
            </Typography>
            <AddIcon />
          </Box>
        </Button>
      </Grid>
      <EmployeesCreateModal open={isOpenEmployeesCreation} onClose={handleClose} />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMoreVertClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => setDeleteMessageModalOpen(true)}>{t('buttons.delete')}</MenuItem>
      </Menu>

      {pendingStatus ? <ProgressOverlay /> : ''}
      {planMessageVisible && (
        <MessageModal
          open={planMessageVisible}
          modalMessage={t('messages.limitEmployees')}
          actionButtonText={t('buttons.ok')}
          actionButtonFn={() => navigate(ROUTES.PRICING)}
          goBackButtonText={t('buttons.later')}
          onClose={() => {
            setPlanMessageVisible(false);
          }}
        />
      )}
      {deleteMessageModalOpen && (
        <MessageModal
          open={deleteMessageModalOpen}
          modalTitle={t('messages.deleteEmployee')}
          modalMessage={t('messages.deleteEmployeeDialog')}
          actionButtonText={t('buttons.goBack')}
          actionButtonFn={() => setDeleteMessageModalOpen(false)}
          goBackButtonText={t('buttons.delete')}
          onClose={() => {
            handleProfileDelete();
          }}
        />
      )}
    </Grid>
  );
};

export default EmployeeProfilesV2;
