import React, { useRef, useState, useEffect } from 'react';
import { Button, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import styles from './NotesTab.module.scss';
import AddIcon from '@mui/icons-material/Add';

import {
  BusinessProfileNotes,
  deleteBusinessProfileNotes,
  selectBusinessProfileStatus,
  updateBusinessProfileNotes,
} from '../../slices/business-profile/businessProfileSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import ConfirmModal from '../confirm-modal/ConfirmModal';
import BrushIcon from '../../../static/assets/svg/brush.svg';
import NotesEditButton from './NotesEditButton';
import NoteInput from '../form-inputs/note-input/NoteInput';
import { selectUser } from '../../slices/user/userSlice';
import { Helpers } from '../helpers/helpers';
import { NWClient } from '../../client/NWClient';
import {
  deleteEmployeeNote,
  fetchEmployeeById,
  fetchEmployeeNotes,
  fetchEmployees,
} from '../../slices/employees/employeesSlice';
import MessageModal from '../message-modal/MessageModal';

interface NotesCardProps {
  note?: BusinessProfileNotes;
  empnote?: any;
}

export default function NotesCard({ empnote, note }: NotesCardProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const businessProfileStatus = useAppSelector(selectBusinessProfileStatus);
  const isLoading = businessProfileStatus === 'loading';

  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);

  const toggleEditing = () => {
    setIsEdited((prev) => !prev);
  };

  const saveChanges = () => {
    if (!note?.id && !empnote?.id) return;

    if (note?.id) {
      dispatch(
        updateBusinessProfileNotes({
          companyId: note.company,
          id: note.id,
          data: formData,
        })
      );
    }

    // TODO : REFACTOR TO USE REDUX

    if (empnote?.id) {
      empFormData.user = user.id;
      NWClient.put(
        `employee/${empnote.employee_id}/notes`,
        empnote.id,
        empFormData,
        true,
        true
      ).then((response) => {
        dispatch(fetchEmployeeById(empnote.employee_id));
      });
    }

    setIsEdited(false);
    setShowModal(false);
  };

  const handleDelete = () => {
    if (!note?.id && !empnote?.id) return;

    if (note?.id) {
      dispatch(deleteBusinessProfileNotes({ companyId: note.company, id: note.id }));
    }

    // REFACTOR TO USE REDUX
    if (empnote?.id) {
      dispatch(
        deleteEmployeeNote({
          employeeId: empnote.employee_id,
          noteId: empnote.id,
        })
      );
    }
    setIsEdited(false);
  };

  const confirmExit = () => {
    setIsEdited(false);
    setShowModal(false);
  };

  const [formData, setFormData] = useState<BusinessProfileNotes>({
    id: note?.id || 0,
    company: note?.company || 0,
    user: note?.user || 0,
    user_name: note?.user_name || '',
    updated: note?.updated || '',
    note: note?.note || '',
  });

  const [empFormData, setEmpFormData] = useState({
    id: empnote?.id || 0,
    employee_id: empnote?.employee_id || 0,
    employee_name: empnote?.employee_name || '',
    note: empnote?.note || '',
    updated: empnote?.updated || '',
    user: empnote?.user || 0,
    user_name: empnote?.user_name || '',
  });

  const handleChange =
    <K extends keyof BusinessProfileNotes>(field: K) =>
    (value: BusinessProfileNotes[K]) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

  const handleEmpChange = (field: string) => (value: string) => {
    setEmpFormData((prev) => ({ ...prev, [field]: value, user: user.id }));
  };

  // USE EFFECT TO HANDLE CLICKING OUTSIDE WHILE EDITING ATTRIBUTES
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isEdited && formRef.current && !formRef.current.contains(event.target as Node)) {
        if (event.target instanceof HTMLElement) {
          const ignoredElements = ['BUTTON', 'INPUT', 'TEXTAREA', 'SELECT', 'LABEL'];
          if (ignoredElements.includes(event.target.tagName)) return;
        }

        setTimeout(() => {
          setShowModal(true);
        }, 0);
      }
    };

    if (isEdited) document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdited]);

  return (
    <Card className={`${styles.card} ${isEdited ? styles.card_border : ''}`} ref={formRef}>
      <CardContent
        className={isLoading ? styles.card_content_min_height : styles.notes_card}
        sx={{ paddingBottom: '16px !important' }}
      >
        {note && (
          <Grid sx={{ width: '900%' }} item>
            {!isEdited ? (
              <div className={styles.typography}>
                <Typography variant='h6' sx={{ marginBottom: '0.5rem' }}>
                  {formData.note}
                </Typography>
                <Grid display='flex'>
                  <Typography variant='body1'>
                    {Helpers.timeStampToDateV3(formData.updated)}
                  </Typography>
                  <Typography variant='body1' sx={{ mx: 0.5 }}>
                    - By
                  </Typography>
                  <Typography variant='body1'>{formData.user_name}</Typography>
                </Grid>
              </div>
            ) : (
              <NoteInput
                value={formData.note}
                onChange={handleChange('note')}
                disabled={!isEdited}
              />
            )}
          </Grid>
        )}

        {empnote && (
          <Grid sx={{ width: '900%' }} item>
            {!isEdited ? (
              <div className={styles.typography}>
                <Typography variant='h6' sx={{ marginBottom: '0.5rem' }}>
                  {empFormData.note}
                </Typography>
                <Grid display='flex'>
                  <Typography variant='body1'>
                    {Helpers.timeStampToDateV3(empFormData.updated)}
                  </Typography>
                  <Typography variant='body1' sx={{ mx: 0.5 }}>
                    - By
                  </Typography>
                  <Typography variant='body1'>{empFormData.user_name}</Typography>
                </Grid>
              </div>
            ) : (
              <NoteInput
                value={empFormData.note}
                onChange={handleEmpChange('note')}
                disabled={!isEdited}
              />
            )}
          </Grid>
        )}
        <Grid container alignItems='center'>
          <Grid container justifyContent='flex-end'>
            {!isEdited ? (
              <NotesEditButton onEdit={toggleEditing} onDelete={handleDelete} />
            ) : (
              <Button
                type='submit'
                className='btn-default btn-action-basic'
                role='button'
                disabled={!isEdited}
                variant='contained'
                size='large'
                onClick={saveChanges}
              >
                {t('buttons.save')}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <ConfirmModal open={showModal} onClose={saveChanges} onConfirm={confirmExit} />
    </Card>
  );
}
