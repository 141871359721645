import Autocomplete from '@mui/material/Autocomplete';
import React, { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import { useAppSelector } from '../../hooks/useAppSelector';
import { selectCountriesInfo } from '../../../slices/countries/countriesSlice';
import { useTranslation } from 'react-i18next';

interface CountrySelectorProps {
  value: { value: string; title: string };
  setCountry: React.SetStateAction<any>;
  disabled: boolean;
}

const CountrySelector = ({ value, setCountry, disabled }: CountrySelectorProps) => {
  const { t } = useTranslation();
  const countries = useAppSelector(selectCountriesInfo);
  const countriesOptions = [...countries]
    .filter((el) => el.code !== 'GB')
    .map((el) => ({ value: el.name, title: el.name }));
  return (
    <FormControl className='w-100'>
      <Autocomplete
        className='w-100'
        id='country'
        disabled={disabled}
        openOnFocus={true}
        options={countriesOptions}
        isOptionEqualToValue={(option, value) => option?.title === value?.title}
        getOptionLabel={(option) => option.title}
        value={value}
        onChange={(
          event: ChangeEvent<HTMLInputElement>,
          newValue: {
            title: string;
            value: string;
          } | null
        ) => {
          setCountry(newValue);
        }}
        renderOption={(props, option, { selected }) => {
          const { ...optionProps } = props;
          return (
            <li key={option.value} {...optionProps}>
              {option.title}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='standard'
            required={true}
            disabled={disabled}
            label={t('labels.country')}
          />
        )}
      />
    </FormControl>
  );
};

export default CountrySelector;
