import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  IconButton,
  TextField,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  FormControlLabel,
} from '@mui/material';
import BrushIcon from '../../../../../static/assets/svg/brush.svg';
import styles from './DocumentDetails.module.scss';
import DocxIcon from '../../../../../static/assets/svg/docx_icon.svg';

const DocumentDetails: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.sectionHeader}>
        <Typography variant='h6' className={styles.sectionTitle}>
          Properties
        </Typography>
        <IconButton
          size='small'
          className={`${styles.editButton} ${isEditing ? styles.activeEdit : ''}`}
          onClick={handleEditToggle}
        >
          <BrushIcon />
        </IconButton>
      </Box>

      <Paper className={styles.propertiesContainer} elevation={0}>
        <Box className={styles.propertyItem}>
          <TextField
            fullWidth
            label='File name'
            defaultValue='Employment Agreement Full'
            variant='outlined'
            size='medium'
            className={`${styles.propertyValue} ${!isEditing ? styles.disabledField : ''}`}
            // disabled={!isEditing}
            InputProps={{
              endAdornment: (
                <Box component='span' sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                  <DocxIcon />
                </Box>
              ),
            }}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Profile'
              defaultValue='Employment'
              variant='outlined'
              size='medium'
              className={styles.propertyValue}
              sx={{ marginBottom: 2 }}
              // disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Profile name'
              defaultValue='John Doe'
              variant='outlined'
              size='medium'
              className={styles.propertyValue}
              // disabled={!isEditing}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Document workflow'
              defaultValue='Onboarding'
              variant='outlined'
              size='medium'
              className={styles.propertyValue}
              sx={{ marginBottom: 2 }}
              // disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Document type'
              defaultValue='Agreement or contract'
              variant='outlined'
              size='medium'
              className={styles.propertyValue}
              // disabled={!isEditing}
            />
          </Grid>
        </Grid>

        <Box className={styles.propertyItem}>
          <TextField
            fullWidth
            label='Labels'
            defaultValue='Onboarding'
            variant='outlined'
            size='medium'
            className={styles.propertyValue}
            // disabled={!isEditing}
          />
        </Box>
      </Paper>

      <Box className={styles.section}>
        <Typography variant='h6' className={styles.sectionTitle}>
          Extras
        </Typography>

        <Paper className={styles.propertiesContainer} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Start date'
                defaultValue='12.Jan.2025'
                variant='outlined'
                size='medium'
                className={styles.propertyValue}
                sx={{ marginBottom: 2 }}
                // disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='End / renewal date'
                defaultValue='-'
                variant='outlined'
                size='medium'
                className={styles.propertyValue}
                // disabled={!isEditing}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Amount'
                defaultValue='AUD 5000'
                variant='outlined'
                size='medium'
                className={styles.propertyValue}
                sx={{ marginBottom: 2 }}
                // disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Subscription'
                defaultValue='Yes / no'
                variant='outlined'
                size='medium'
                className={styles.propertyValue}
                // disabled={!isEditing}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Frequency'
                defaultValue='Monthly'
                variant='outlined'
                size='medium'
                className={styles.propertyValue}
                // disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Signed'
                defaultValue='Yes / no'
                variant='outlined'
                size='medium'
                className={styles.propertyValue}
                // disabled={!isEditing}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Paper className={styles.propertiesContainer} elevation={0} sx={{ marginTop: '-42px' }}>
        <Box
          className={styles.section}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 16px 0 16px',
          }}
        >
          <Typography variant='h6' className={styles.sectionTitle} sx={{ ml: -1 }}>
            Versions
          </Typography>
          <FormControlLabel
            control={<Switch />}
            label='Latest version'
            labelPlacement='start'
            sx={{ margin: 0 }}
          />
        </Box>
        <TableContainer sx={{ marginTop: '-16px', ml: 2 }}>
          <Table className={styles.versionTable}>
            <TableHead>
              <TableRow>
                <TableCell className={styles.versionTableHeader}>Version</TableCell>
                <TableCell className={styles.versionTableHeader}>Date created</TableCell>
                <TableCell className={styles.versionTableHeader}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={styles.versionTableRow}>
                <TableCell>Version 0</TableCell>
                <TableCell>15 Jan 2025</TableCell>
                <TableCell align='right'>
                  <Button variant='contained' className={styles.viewContentButton}>
                    View content
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow className={styles.versionTableRow}>
                <TableCell>Version 1</TableCell>
                <TableCell>15 Feb 2025</TableCell>
                <TableCell align='right'>
                  <Button variant='contained' className={styles.viewContentButton}>
                    View content
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow className={styles.versionTableRow}>
                <TableCell>Version 2</TableCell>
                <TableCell>15 Feb 2025</TableCell>
                <TableCell align='right'>
                  <Button variant='contained' className={styles.viewContentButton}>
                    View content
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow className={styles.versionTableRow}>
                <TableCell>Version 3</TableCell>
                <TableCell>15 Feb 2025</TableCell>
                <TableCell align='right'>
                  <Button variant='contained' className={styles.viewContentButton}>
                    View content
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default DocumentDetails;
