import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import DetailsCard from '../../../common/details-card/DetailsCard';
import OrganizationInfoCard from '../../../common/organization-info-card/OrganizationInfoCard';

import DocumentList from '../../../common/document-list/DocumentList';
import styles from './AllTabs.module.scss';
import { Company } from '../../../models/company.model';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { fetchDocuments, selectAllDocuments } from '../../../slices/documents/documentsSlice';
import {
  fetchDocumentContracts,
  selectAllDocumentContracts,
} from '../../../slices/document-contracts/documentContractsSlice';
import { Employee } from '../../../models/employee.model';
import EmploymentCard from '../../../common/employment-card/EmploymentCard';
import EmployeeDetailsCard from '../../../common/employee-details-card/EmployeeDetailsCard';
import DocumentListVault from '../../document-vault/components/document-list-vault/DocumentListVault';
import { useAppSelector } from '../../../common/hooks/useAppSelector';

interface AllTabsProps {
  company: Company;
  employee: Employee;
  documents: any[];
  key: string;
}

const AllTabs: React.FC<AllTabsProps> = ({ company, employee, documents }) => {
  const { t } = useTranslation();

  const sortByDate = (a: any, b: any) => {
    return Number(new Date(b.created)) - Number(new Date(a.created));
  };

  // useEffect(() => {
  //   const fetchAllDocuments = async () => {
  //     // For every member, dispatch both actions and wait for both results
  //     const results = await Promise.all(
  //       company.members.map(async (member) => {
  //         console.log('Member:', member);
  //         const [docsAction, contractsAction] = await Promise.all([
  //           dispatch(fetchDocuments({ data: { user: member.id, category: 'employment' } })),
  //           dispatch(fetchDocumentContracts({ data: { user: member.id, category: 'employment' } })),
  //         ]);
  //         // Assuming the payloads contain the resulting documents/objects
  //         return [
  //           ...(Array.isArray(docsAction.payload) ? docsAction.payload : []),
  //           ...(Array.isArray(contractsAction.payload) ? contractsAction.payload : []),
  //         ];
  //       })
  //     );
  //     const filteredDocuments = results
  //       .flat()
  //       .filter((doc) => doc.employees?.includes(employee.id));
  //     setDocuments(filteredDocuments);
  //   };

  //   fetchAllDocuments();
  // }, [company.members, dispatch]);

  return (
    <Grid container rowGap={1}>
      <Grid container spacing={2} className={styles.equal_height_container}>
        <Grid item xs={12} sm={12} md={6} className={styles.equal_height_item}>
          <EmployeeDetailsCard simplified employee={employee} company={company} />
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={styles.equal_height_item}>
          <EmploymentCard simplified employee={employee} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <DocumentListVault documents={documents} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AllTabs;
