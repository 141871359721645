import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';
import {
  Grid,
  Typography,
  Stack,
  Button,
  IconButton,
  Box,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { useTranslation } from 'react-i18next';
import BasicTabs from '../../common/basic-tabs/BasicTabs';
import AllTabs from './components/AllTabls';
import DetailsTabs from './components/DetailsTab';
import DocumentsTabs from './components/DocumentsTabs';
import NotesTab from '../../common/notes-tab/NotesTab';
import FormUpload from '../../common/form-upload/FormUpload';
import UploadButton from '../../common/upload-button/UploadButton';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import {
  fetchBusinessProfileById,
  selectBusinessProfileDetails,
} from '../../slices/business-profile/businessProfileSlice';
import styles from './EmployeeProfileV2.module.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CreateContactModal from '../../common/create-contact-modal/CreateContactModal';
import { fetchCompanies, selectAllCompanies } from '../../slices/companies/companiesSlice';
import {
  fetchEmployees,
  selectAllEmployees,
  selectEmployeeById,
} from '../../slices/employees/employeesSlice';
import { RootState } from '../../store';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchDocuments, selectAllDocuments } from '../../slices/documents/documentsSlice';
import {
  fetchDocumentContracts,
  selectAllDocumentContracts,
} from '../../slices/document-contracts/documentContractsSlice';
import { selectUser } from '../../slices/user/userSlice';

const EmployeeProfileV2 = () => {
  const dispatch = useAppDispatch();
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const company = useAppSelector(selectAllCompanies)[0];
  const formUploadRef = useRef<HTMLDivElement>(null);
  const uploadButtonRef = useRef<HTMLButtonElement>(null);
  const { employeeId } = useParams();
  const parsedEmployeeId = Number(employeeId);
  const currentUser = useAppSelector(selectUser);
  const sortByDate = (a: any, b: any) => {
    return Number(new Date(b.created)) - Number(new Date(a.created));
  };
  const documentContracts = useAppSelector(selectAllDocumentContracts);
  const documentUploads = useAppSelector(selectAllDocuments);
  const combinedDocuments = [...documentContracts, ...documentUploads]
    .slice()
    .sort((a, b) => sortByDate(a, b))
    .filter((doc) => doc.employees?.includes(parsedEmployeeId));

  const employee = useAppSelector((state: RootState) =>
    selectEmployeeById(state, parsedEmployeeId)
  );

  const [searchParams] = useSearchParams();
  const initialTab = Number(searchParams.get('initialTab')) || 0;

  useEffect(() => {
    dispatch(fetchDocuments({ data: { user: currentUser?.id, employees: parsedEmployeeId } }));
    dispatch(
      fetchDocumentContracts({ data: { user: currentUser?.id, employees: parsedEmployeeId } })
    );
  }, []);

  // Second useEffect that processes data whenever the Redux store updates

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  useEffect(() => {
    if (initialTab === 2) {
      setIsUploadOpen(true);
    }
  }, [initialTab]);

  useEffect(() => {
    if (company?.id) {
      dispatch(fetchEmployees({ company_id: company.id }));
    }
  }, [company]);

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleUploadClose = () => {
    setIsUploadOpen(false);
  };

  // Actually handles both open & close
  const handleUploadOpen = () => {
    setIsUploadOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.closest('.MuiMenuItem-root') || // Ignore clicks on MenuItem
        targetElement.closest('.MuiSelect-root') // Ignore clicks on Select
      ) {
        return;
      }
      if (
        formUploadRef.current &&
        !formUploadRef.current.contains(event.target as Node) &&
        uploadButtonRef.current &&
        !uploadButtonRef.current.contains(event.target as Node)
      ) {
        setIsUploadOpen(false);
      }
    };

    if (isUploadOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isUploadOpen]);

  const toogleShowAddlModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  if (!company || !employee) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3} sx={{ mt: '-100px' }}>
      <Grid item xs={12}>
        <Stack spacing={2} mb={3}>
          <Typography variant='h5' component='h1' className={styles.page_title}>
            Employee Profile
          </Typography>
          <Stack direction='row' spacing={2}>
            <Box ref={uploadButtonRef}>
              <UploadButton onClick={handleUploadOpen} buttonText='Upload' isArrowDown={false} />
            </Box>
            {/*!isUploadOpen && (
              <Box onClick={handleUploadOpen} sx={{ display: 'flex', alignItems: 'center' }}>
                <AddIcon
                  className='add-icon'
                  style={{ cursor: 'pointer' }}
                  onClick={handleUploadOpen}
                />
              </Box>
            )*/}
            {isUploadOpen && (
              <Box className={styles.form_box} ref={formUploadRef}>
                <FormUpload
                  onCancel={handleUploadClose}
                  categoryValue='employment'
                  employees={[employee]} // Pass employees data
                />
              </Box>
            )}
            {/* <Tooltip title={t('businessProfile.addContacts')} placement='top'>
              <IconButton onClick={toogleShowAddlModal} className={styles.add_button}>
                <AddIcon />
              </IconButton>
            </Tooltip> */}
          </Stack>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight='bold'>
              {employee.first_name}
            </Typography>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} className={styles.grid_margin_bottom}>
        <BasicTabs
          tabs={[
            t('businessProfile.allTab'),
            t('businessProfile.detailsTab'),
            t('businessProfile.documentsTabs'),
            t('businessProfile.notesTab'),
          ]}
          tabContents={[
            <AllTabs
              employee={employee}
              company={company}
              documents={combinedDocuments}
              key='all'
            />,
            <DetailsTabs employee={employee} company={company} key='details' />,
            <DocumentsTabs documents={combinedDocuments} key='documents' />,
            <NotesTab employee={employee} key='notes' />,
          ]}
          initialTab={initialTab}
        />
      </Grid>
      {isModalOpen && (
        <CreateContactModal
          companyId={company.id}
          open={isModalOpen}
          onClose={() => toogleShowAddlModal()}
        />
      )}
    </Grid>
  );
};

export default EmployeeProfileV2;
