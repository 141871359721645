import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './ViewDocument.module.scss';

const ViewDocument: React.FC = () => {
  return (
    <Box className={styles.documentPreview}>
      <Box className={styles.documentContent}>
        <Typography variant='h5' align='center' gutterBottom>
          Employment Agreement
        </Typography>
        <Typography variant='h6' align='center' gutterBottom>
          Full Time
        </Typography>
        <Typography variant='h6' align='center' gutterBottom sx={{ mb: 4 }}>
          Details Schedule
        </Typography>

        <Box className={styles.documentTable}>
          <Box className={styles.tableRow}>
            <Box className={styles.tableHeader}>Employment Agreement Date</Box>
          </Box>
          <Box className={styles.tableRow}>
            <Box className={styles.tableCell}>
              The Agreement Date is the date on which the last party signs this Agreement
            </Box>
          </Box>
          <Box className={styles.tableRow}>
            <Box className={styles.tableHeader}>Parties</Box>
          </Box>
          <Box className={styles.tableRow}>
            <Box className={styles.tableCell}>The Employer and Employee</Box>
          </Box>
          <Box className={styles.tableRow}>
            <Box className={styles.tableHeader}>The Employer</Box>
          </Box>
          <Box className={styles.tableRow}>
            <Box className={styles.tableCell}>
              <Typography component='div'>
                <strong>Organisation name:</strong> [Insert employer organisation name]
              </Typography>
              <Typography component='div'>
                <strong>Organisation number:</strong> [Insert employer i.e. ACN or ABN]
              </Typography>
              <Typography component='div'>
                <strong>Address:</strong> [Insert employer address]
              </Typography>
              <Typography component='div'>
                <strong>Phone number:</strong> [Insert employer phone number]
              </Typography>
              <Typography component='div'>
                <strong>Email address:</strong> [Insert employer email address]
              </Typography>
            </Box>
          </Box>
          <Box className={styles.tableRow}>
            <Box className={styles.tableHeader}>The Employee</Box>
          </Box>
          <Box className={styles.tableRow}>
            <Box className={styles.tableCell}>
              <Typography component='div'>
                <strong>Full name:</strong> [Insert employee full name]
              </Typography>
              <Typography component='div'>
                <strong>Address:</strong> [Insert employee address]
              </Typography>
              <Typography component='div'>
                <strong>Phone number:</strong> [Insert employee phone number]
              </Typography>
              <Typography component='div'>
                <strong>Email address:</strong> [Insert employee email address]
              </Typography>
            </Box>
          </Box>
          <Box className={styles.tableRow}>
            <Box className={styles.tableHeader}>Start Date</Box>
          </Box>
          <Box className={styles.tableRow}>
            <Box className={styles.tableCell}>
              <Typography component='div'>[Insert employee start date]</Typography>
              <Typography component='div' sx={{ mt: 2 }}>
                <strong>Position:</strong> Full-time Employee
              </Typography>
              <Typography component='div'>
                <strong>Probation period:</strong> 3 months from Start Date
              </Typography>
              <Typography component='div'>
                <strong>Hours of work:</strong> 38 hours per week
              </Typography>
              <Typography component='div'>
                <strong>Location:</strong> [Insert workplace location]
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewDocument;
