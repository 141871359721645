import React, { useEffect, useState } from 'react';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { Box, Grid, Typography, Button } from '@mui/material';
import styles from './DemoDocumentWorkspace.module.scss';
import CreateDocument from './components/create-document-workspace/CreateDocument';
import DetailsDocument from './components/details-document-workspace/DetailsDocument';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomStepper from '../../common/custom-stepper/CustomStepper';
import ViewDocument from './components/view-document-workspace/ViewDocument';

const DemoDocumentWorkspace: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(switchDesign(true));
  }, [dispatch]);

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);

    setCompleted((prev) => ({
      ...prev,
      [activeStep]: true,
    }));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStepClick = (step: number) => {
    if (completed[step] || step === activeStep || step === activeStep + 1) {
      setActiveStep(step);
    }
  };

  const steps = ['Create a document', 'Details', 'View'];

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant='h5' component='h1' className={styles.title}>
          Document Workspace
        </Typography>
        <Box className={styles.breadcrumbs}>
          <Typography component='span' className={styles.separator}>
            {'>'}
          </Typography>
          <Typography component='span'>Business Kitz library</Typography>
          <Typography component='span' className={styles.separator}>
            {'>'}
          </Typography>
          <Typography component='span'>Creating an employment contract</Typography>
        </Box>
      </Box>

      <Grid container className={styles.content}>
        <CustomStepper
          steps={steps}
          activeStep={activeStep}
          completed={completed}
          onStepClick={handleStepClick}
        />
        <Grid item xs={5.5} className={styles.formSection}>
          <Box className={styles.formContent}>
            {activeStep === 0 && <CreateDocument />}
            {activeStep === 1 && <DetailsDocument />}
            <Box className={styles.actions}>
              {activeStep > 0 && (
                <Button onClick={handleBack} className={styles.backButton} variant='outlined'>
                  Back
                </Button>
              )}
              <Button
                variant='contained'
                onClick={handleNext}
                className={styles.nextButton}
                disabled={activeStep === steps.length - 1}
              >
                {activeStep === 0 ? 'Next' : 'Create'}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6.5} className={styles.documentPreviewSection}>
          <ViewDocument />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DemoDocumentWorkspace;
