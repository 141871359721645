import { CountryModel } from '../../models/countries.model';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { RootState } from '../../store';

export const fetchCountries = createAsyncThunk('user/fetchCountries', async () => {
  const response: CountryModel[] = await NWClient.list('country');
  return response;
});

export interface CountriesInfoState {
  info: CountryModel[];
  status: string;
  error: string;
}

const initialState: CountriesInfoState = {
  info: [],
  status: '',
  error: '',
};

const countriesInfoSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.info = action.payload;
      })
      .addCase(fetchCountries.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default countriesInfoSlice.reducer;

export const selectCountriesInfo = (state: RootState) => state.countriesInfo.info;
