import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileSaveIcon from '../../../../../static/assets/svg/file_save.svg';
import BrushIcon from '../../../../../static/assets/svg/brush.svg';
import CalendarIcon from '../../../../../static/assets/svg/calendar_month.svg';
import styles from './DocumentListVault.module.scss';
import { Helpers } from '../../../../common/helpers/helpers';
import { v4 as uuidv4 } from 'uuid';
import { DocumentListVaultProps, Document } from './types';
import PdfIcon from '../../../../../static/assets/svg/pdf_icon.svg';
import DocxIcon from '../../../../../static/assets/svg/docx_icon.svg';
import BKIcon from '../../../../../static/assets/svg/bk_icon.svg';
import EmployeeIcon from '@mui/icons-material/BadgeOutlined';
import CustomersIcon from '@mui/icons-material/SupportAgentOutlined';
import FileTypeDownloadModal from '../../../../common/file-type-download-modal/FileTypeDownloadModal';
import { useFileTypeDownload } from '../../../../common/file-type-download-modal/useFileTypeDownload';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import { DocumentsEditButton } from '../../../../common/document-edit-button/DocumentsEditButton';
import MessageModal from '../../../../common/message-modal/MessageModal';
import { useDeleteMessageModal } from '../../hooks/useDeleteMessageModal';
import { useAISummaryDialog } from '../../../../common/ai-summary-dialog-v2/useAISummaryDialog';
import AISummaryDialog from '../../../../common/ai-summary-dialog-v2/AISummaryDialog';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants/routes';
import ContractEditIcon from '../../../../../static/assets/svg/menu/contract-edit.svg';
import { useDocumentSigning } from '../../hooks/useDocumentSigning';
import { useDocumentsFunctions } from '../../hooks/useDocumentsFunctions';

const DocumentListVault = ({ documents }: DocumentListVaultProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    getWorkflow,
    getFileTypeFromName,
    getProfileName,
    getDocumentType,
    getProfileType,
    navigateToDocumentPageTab,
  } = useDocumentsFunctions();
  const {
    setModalOpen: setDeleteMessageModalOpen,
    modalOpen: deleteMessageModalOpen,
    pendingStatus: documentDeletePendingStatus,
    documentToDelete,
    setDocumentToDelete,
    handleDeleteDocument,
  } = useDeleteMessageModal();
  const {
    setModalOpen: setFileTypeModalOpen,
    modalOpen: fileTypeModalOpen,
    handleCloseModal: handleCloseFileTypeModal,
    setDocument: setDocumentForDownloading,
    document: documentForDownloading,
    setFileName: setFileNameForDownloading,
    fileName: fileNameForDownloading,
    fileType: fileTypeForDownloading,
    setFileType: setFileTypeForDownloading,
    handleDownloadContract,
    downloadDocumentUploadItem,
    handleDownloadHTML,
    pendingStatus: downloadPendingStatus,
  } = useFileTypeDownload();
  const {
    dialogOpen: aiSummaryDialogOpen,
    setDialogOpen: setAISummaryDialogOpen,
    document: documentForAISummary,
    setDocument: setDocumentForAISummary,
    planUpgradingMessage: planUpgradingMessageAISummary,
    setPlanUpgradingMessage: setPlanUpgradingMessageAISummary,
  } = useAISummaryDialog();
  const {
    document: documentToSign,
    setDocument: setDocumentToSign,
    dialogOpen: signingDialogOpen,
    setDialogOpen: setSigningDialogOpen,
    planUpgradingMessage: planUpgradingMessageSigning,
    pendingStatus: pendingStatusSigning,
    handleSignOriginalDocument,
    handleSignLatestVersion,
  } = useDocumentSigning();
  const renderProfileIcon = (profileType: string) => {
    switch (profileType) {
      case 'employment':
        return <EmployeeIcon fontSize='small' />;
      case 'supplier':
        return <BusinessCenterOutlinedIcon fontSize='small' />;
      case 'customer':
        return <CustomersIcon fontSize='small' />;
      case 'business':
        return <ApartmentOutlinedIcon fontSize='small' />;
      default:
        return null;
    }
  };
  const renderFileTypeIcon = (fileType: string) => {
    switch (fileType.toLowerCase()) {
      case 'pdf':
        return <PdfIcon />;
      case 'docx':
        return <DocxIcon />;
      case 'doc':
        return <DocxIcon />;
      case 'bk':
        return <BKIcon />;
      default:
        return null;
    }
  };
  const renderTableHeader = () => (
    <TableHead>
      <TableRow className={styles.header_row}>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('documentsTable.profile')}
          </Typography>
        </TableCell>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('documentsTable.fileName')}
          </Typography>
        </TableCell>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('documentsTable.fileType')}
          </Typography>
        </TableCell>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('documentsTable.workflow')}
          </Typography>
        </TableCell>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('documentsTable.documentType')}
          </Typography>
        </TableCell>
        <TableCell className={styles.header_cell}>
          <Typography variant='body2' className={styles.header_text}>
            {t('documentsTable.date')}
          </Typography>
        </TableCell>
        <TableCell className={`${styles.header_cell} ${styles.actions_header}`}>
          <Typography variant='body2' className={styles.header_text}>
            {t('documentsTable.actions')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const renderActionButtons = (doc: Document) => (
    <Box className={styles.actions_container}>
      <IconButton
        size='small'
        onClick={() => {
          if (doc.contract_generation) {
            setDocumentForDownloading(doc);
            setFileNameForDownloading(doc.name);
            setFileTypeModalOpen(true);
          } else {
            if (doc.document_upload_html.length > 1) {
              setDocumentForDownloading(doc);
              setFileTypeModalOpen(true);
            } else {
              downloadDocumentUploadItem(doc.id, doc.name);
            }
          }
        }}
        className={styles.action_button}
      >
        <FileSaveIcon />
      </IconButton>
      <IconButton
        size='small'
        onClick={() => {
          setDocumentToSign(doc);
          if ('document_upload_html' in doc && doc.document_upload_html.length > 1) {
            setSigningDialogOpen(true);
          } else {
            handleSignOriginalDocument(doc);
          }
        }}
        className={styles.action_button}
      >
        <ContractEditIcon />
      </IconButton>
      <IconButton
        size='small'
        onClick={() => navigateToDocumentPageTab(doc, 1)}
        className={styles.action_button}
      >
        <BrushIcon />
      </IconButton>
      <DocumentsEditButton
        document={doc}
        getDocumentSummaryFn={() => {
          setDocumentForAISummary(doc);
          setAISummaryDialogOpen(true);
        }}
        createTemplateFn={() => navigateToDocumentPageTab(doc, 4)}
        deleteDocumentFn={() => {
          setDocumentToDelete(doc);
          setDeleteMessageModalOpen(true);
        }}
        documentVersionsFn={() => navigateToDocumentPageTab(doc, 2)}
        documentLabelsFn={() => navigateToDocumentPageTab(doc, 3)}
      />
    </Box>
  );

  return (
    <>
      <TableContainer
        component={Paper}
        className={styles.table_container}
        sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      >
        <Table>
          {renderTableHeader()}
          <TableBody>
            {documents.length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>
                  <p className={styles.empty_message}>{t('messages.emptyDocumentsMessage')}</p>
                </TableCell>
              </TableRow>
            )}
            {documents.map((doc) => {
              const profileType = getProfileType(doc.category);
              const profileIcon = renderProfileIcon(profileType);
              const fileTypeIcon = renderFileTypeIcon(
                'du_filetype' in doc
                  ? doc.du_filetype.length > 0
                    ? doc.du_filetype[0]
                    : getFileTypeFromName(doc.name, doc.contract_generation)
                  : 'bk'
              );

              return (
                <TableRow key={`${doc.id}-${uuidv4()}`} className={styles.table_row}>
                  <TableCell
                    className={styles.table_cell}
                    onClick={() => navigateToDocumentPageTab(doc, 0)}
                  >
                    <Box className={styles.profile_container}>
                      {profileIcon && <Box className={styles.profile_icon}>{profileIcon}</Box>}
                      <Typography variant='body2' className={styles.profile_text}>
                        {getProfileName(profileType, doc).join(', ')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    className={styles.table_cell}
                    onClick={() => navigateToDocumentPageTab(doc, 0)}
                  >
                    <Typography variant='body2' className={styles.file_name_container}>
                      {doc.name.endsWith('.pdf') ||
                      doc.name.endsWith('.docx') ||
                      doc.name.endsWith('.doc')
                        ? doc.name.replace('.pdf', '').replace('.docx', '').replace('.doc', '')
                        : doc.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={styles.table_cell}
                    onClick={() => navigateToDocumentPageTab(doc, 0)}
                  >
                    <Box className={styles.file_type_container}>
                      {fileTypeIcon && <Box className={styles.file_type_icon}>{fileTypeIcon}</Box>}
                    </Box>
                  </TableCell>
                  <TableCell
                    className={styles.table_cell}
                    onClick={() => navigateToDocumentPageTab(doc, 0)}
                  >
                    <Typography variant='body2' className={styles.cell_text}>
                      {getWorkflow(doc)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={styles.table_cell}
                    onClick={() => navigateToDocumentPageTab(doc, 0)}
                  >
                    <Typography variant='body2' className={styles.cell_text}>
                      {getDocumentType(doc)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={styles.table_cell}
                    onClick={() => navigateToDocumentPageTab(doc, 0)}
                  >
                    <Box className={styles.date_container}>
                      <CalendarIcon />
                      <Typography variant='body2' className={styles.cell_text}>
                        {Helpers.timeStampToDateV2(doc.created)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={styles.table_cell}>{renderActionButtons(doc)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {fileTypeModalOpen && documentForDownloading && (
        <FileTypeDownloadModal
          onClose={() => {
            handleCloseFileTypeModal();
          }}
          open={fileTypeModalOpen}
          fileName={fileNameForDownloading}
          fileType={fileTypeForDownloading}
          handleFileTypeChange={setFileTypeForDownloading}
          handleFileNameChange={setFileNameForDownloading}
          document={documentForDownloading}
          handleDownloadContract={() => {
            handleDownloadContract(documentForDownloading.id);
          }}
          handleDownloadDocument={() =>
            downloadDocumentUploadItem(documentForDownloading.id, documentForDownloading.name)
          }
          handleDownloadHTML={() => {
            handleDownloadHTML(
              Math.max(...documentForDownloading.document_upload_html),
              documentForDownloading.name.endsWith('.pdf') ||
                documentForDownloading.name.endsWith('.docx') ||
                documentForDownloading.name.endsWith('.doc')
                ? documentForDownloading.name
                    .replace('.pdf', '')
                    .replace('.docx', '')
                    .replace('.doc', '')
                : documentForDownloading.name,
              documentForDownloading.du_filetype[0]
            );
          }}
        />
      )}
      {deleteMessageModalOpen && (
        <MessageModal
          open={deleteMessageModalOpen}
          modalTitle={t('dialogTitles.deleteDocument')}
          modalMessage={t('messages.deleteDocumentDialog')}
          actionButtonText={t('buttons.delete')}
          actionButtonFn={() => handleDeleteDocument()}
          goBackButtonText={t('buttons.goBack')}
          document={documentToDelete}
          onClose={() => {
            setDocumentToDelete(null);
            setDeleteMessageModalOpen(false);
          }}
        />
      )}
      {signingDialogOpen && (
        <MessageModal
          open={signingDialogOpen}
          modalMessage={t('messages.signingVersionMessage')}
          actionButtonText={t('buttons.signLatestVersion')}
          actionButtonFn={() => handleSignLatestVersion(documentToSign)}
          actionButtonAdditionalText={t('buttons.signOriginalDocument')}
          actionButtonAdditionalFn={() => handleSignOriginalDocument(documentToSign)}
          goBackButtonText={t('buttons.goBack')}
          document={documentToSign}
          onClose={() => {
            setDocumentToSign(null);
            setSigningDialogOpen(false);
          }}
        />
      )}
      {aiSummaryDialogOpen && (
        <AISummaryDialog
          open={aiSummaryDialogOpen}
          documentName={documentForAISummary.name}
          onClose={() => {
            setAISummaryDialogOpen(false);
            setDocumentForAISummary(null);
          }}
          handleAnalyzeSuccess={() => navigateToDocumentPageTab(documentForAISummary, 0)}
          documentId={documentForAISummary.id}
          isDocumentContract={Boolean(documentForAISummary.contract_generation)}
        />
      )}
      {(planUpgradingMessageAISummary || planUpgradingMessageSigning) && (
        <MessageModal
          open={planUpgradingMessageAISummary}
          modalMessage={t('messages.monthLimitReached')}
          actionButtonText={t('buttons.ok')}
          actionButtonFn={() => navigate(ROUTES.PRICING)}
          goBackButtonText={t('buttons.later')}
          onClose={() => {
            setPlanUpgradingMessageAISummary(false);
          }}
        />
      )}
      {(downloadPendingStatus || pendingStatusSigning || documentDeletePendingStatus) && (
        <ProgressOverlay />
      )}
    </>
  );
};

export default DocumentListVault;
