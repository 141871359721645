import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotificationsUnreadIcon from '../../../static/assets/svg/notifications-unread.svg';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Container, Menu, MenuItem } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ROUTES } from '../constants/routes';
import { useGreetingUser } from '../../pages/templates/hooks/useWelcomingText';
import { NWClient } from '../../client/NWClient';
import { logout } from '../../slices/user/userSlice';
import { ProgressOverlay } from '../progress-overlay/ProgressOverlay';

const AppBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const designUpdated = useAppSelector((state) => state.designUpdated.designUpdated);
  const { greeting, date } = useGreetingUser();
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(switchDesign(event.target.checked));
  };

  const handleLogOut = () => {
    handleUserMenuClose();
    NWClient.logOut().finally(() => {
      setLoading(true);
      localStorage.removeItem('access_token');
      navigate(ROUTES.LOGIN);
      dispatch(logout());
    });
  };

  return (
    <>
      <div className='application-bar'>
        <Container maxWidth='lg' className='d-flex justify-content-between align-items-center'>
          {path === ROUTES.INDEX && (
            <div className='name-date-container'>
              <div className='name-container'>{greeting}</div>
              <div className='date-container'>{date}</div>
            </div>
          )}
          <div className='flex-grow-1'></div>
          <div className='actions-container d-flex align-items-center position-relative'>
            {path === ROUTES.INDEX && process.env.ENV_TYPE !== 'PROD' && (
              <FormControlLabel
                control={<Switch checked={designUpdated} onChange={handleChange} />}
                label={'Updated design version'}
              />
            )}
            {path === ROUTES.INDEX && (
              <Link className='app-bar-link' to={ROUTES.DOCUMENT_LIBRARY}>
                {t('buttons.browseBusinessKitzLibrary')}
              </Link>
            )}
            {/* <div className='notifications'>
            <NotificationsUnreadIcon />
          </div> */}
            <div
              className='avatar-item'
              role='button'
              tabIndex={-1}
              onKeyDown={() => {
                return;
              }}
              onClick={handleUserMenu}
            >
              <AccountCircleOutlinedIcon />
            </div>
            <Menu
              id='user-menu'
              anchorEl={userMenuAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={Boolean(userMenuAnchorEl)}
              onClose={handleUserMenuClose}
            >
              <MenuItem onClick={() => navigate(ROUTES.SETTINGS)}>
                {t('buttons.accountSettings')}
              </MenuItem>
              <MenuItem onClick={() => handleLogOut()}>{t('buttons.logout')}</MenuItem>
            </Menu>
          </div>
        </Container>
      </div>
      {loading && <ProgressOverlay />}
    </>
  );
};
export default AppBar;
