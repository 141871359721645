import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { RootState } from '../../store';

export interface BusinessProfileBankDetails {
  id: number;
  company: number;
  account_number: string;
  account_name: string;
  swift_code?: string | null;
  country?: string | null;
  currency?: string | null;
}

export interface BusinessProfileContacts {
  id: number;
  company: number;
  contact_name: string;
  contact_email: string;
  contact_phone_number?: string | null;
  contact_position: string;
  contact_address?: string | null;
  is_authorized_signatory: boolean;
  is_shareholder: boolean;
  is_hiring_manager: boolean;
  is_legal_representative: boolean;
}
// export interface Social {
//   name: string;
//   url: string;
// }
export interface BusinessProfileDetails {
  id: number;
  company: number;
  business_email_address: string;
  business_phone_number?: string | null;
  registration_number?: string | null;
  registration_number_2_or_tax_number?: string | null;
  business_country?: string | null;
  registered_address?: string | null;
  physical_address?: string | null;
  postal_address?: string | null;
}

export interface BusinessProfileNotes {
  id: number;
  company: number;
  user: number;
  user_name: string;
  updated: string;
  note: string;
}

interface BusinessProfile {
  bankDetails: BusinessProfileBankDetails[];
  contacts: BusinessProfileContacts[];
  details: BusinessProfileDetails;
  // socials?: Social[];
  notes: BusinessProfileNotes[];
}

interface BusinessProfileState {
  data: BusinessProfile;
  status: string;
  error: string;
}

const initialState: BusinessProfileState = {
  data: {
    bankDetails: [],
    contacts: [],
    details: {} as BusinessProfileDetails,
    notes: [],
  },
  status: 'idle',
  error: '',
};

// export const fetchBusinessProfileSocials = createAsyncThunk(
//   'businessProfile/fetchBusinessProfileSocials',
//   async (_, { rejectWithValue }) => {
//     try {
//       // const response = await NWClient.list<BusinessProfile['details']>(
//       //   'business-profile-details'
//       // );
//       // return response.length > 0 ? response[0] : rejectWithValue('No business profile found');
//       const response: Social[] = [
//         { name: 'website', url: 'https://company.com' },
//         { name: 'facebook', url: 'https://facebook.com' },
//         { name: 'twitter', url: 'https://twitter.com' },
//         { name: 'instagram', url: 'https://instagram.com' },
//         { name: 'linkedin', url: 'https://linkedin.com' },
//         { name: 'youtube', url: 'https://youtube.com' },
//       ];
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// export const updateBusinessProfileSocials = createAsyncThunk<{
//   // id: number;
//   // data: any;
// }>('businessProfile/updateBusinessProfileSocials', async (_, { rejectWithValue }) => {
//   // async ({  id, data }, { rejectWithValue }) => {
//   try {
//     // const response = await NWClient.put<any>( 'business-profile-details', id, data, true);
//     const response: Social[] = [
//       { name: 'website', url: 'https://test.com' },
//       { name: 'facebook', url: 'https://test.com' },
//       { name: 'twitter', url: 'https://test.com' },
//       { name: 'instagram', url: 'https://test.com' },
//       { name: 'linkedin', url: 'https://test.com' },
//       { name: 'youtube', url: '' },
//     ];
//     return response;
//   } catch (error: any) {
//     return rejectWithValue(error.message);
//   }
// });

// export const fetchBusinessProfile = createAsyncThunk<BusinessProfile['details'], string>(
//   'businessProfile/fetchBusinessProfile',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await NWClient.list<BusinessProfile['details']>('business-profile-details');
//       return response.length > 0 ? response[0] : rejectWithValue('No business profile found');
//     } catch (error: any) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const fetchBusinessProfileById = createAsyncThunk<
  BusinessProfile['details'],
  { id: number }
>('businessProfile/fetchBusinessProfileById', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await NWClient.get<BusinessProfile['details'][]>(
      'business-profile-details',
      id
    );
    if (response.length === 0) {
      await NWClient.post(`business-profile-details/${id}`, { company: id }, false);
      await NWClient.post(`business-profile-bank-details/${id}`, { company: id }, false);
    }
    return response[0];
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const createBusinessProfileEntry = createAsyncThunk<
  BusinessProfile['details'],
  { id: number; data: BusinessProfile['details'] }
>('businessProfile/createBusinessProfileEntry', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await NWClient.post<BusinessProfile['details']>(
      `business-profile-details/${id}`,
      data
    );
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const updateBusinessProfileEntry = createAsyncThunk<
  BusinessProfileDetails,
  { id: number; data: BusinessProfileDetails }
>('businessProfile/updateBusinessProfileEntry', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await NWClient.put<BusinessProfileDetails>(
      `business-profile-details/${id}`,
      data.id,
      data,
      true
    );
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const deleteBusinessProfileEntry = createAsyncThunk(
  'businessProfile/deleteBusinessProfileEntry',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      await NWClient.delete(`business-profile-details/${id}`, 1);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// export const mockBusinessProfileContacts: BusinessProfileContacts[] = [
//   {
//     id: 1,
//     company: 5,
//     contact_name: 'John Doe',
//     contact_email: 'johndoe@example.com',
//     contact_phone_number: '+123456789',
//     contact_position: 'Director',
//     contact_address: '123 Main St, City, Country',
//     is_authorized_signatory: true,
//     is_shareholder: false,
//     is_hiring_manager: true,
//     is_legal_representative: false,
//   },
//   {
//     id: 2,
//     company: 5,
//     contact_name: 'Jane Smith',
//     contact_email: 'janesmith@example.com',
//     contact_phone_number: '+987654321',
//     contact_position: 'Accountant',
//     contact_address: '456 Finance St, City, Country',
//     is_authorized_signatory: false,
//     is_shareholder: true,
//     is_hiring_manager: false,
//     is_legal_representative: true,
//   },
//   {
//     id: 3,
//     company: 5,
//     contact_name: 'Alice Johnson',
//     contact_email: 'alicejohnson@example.com',
//     contact_phone_number: null,
//     contact_position: 'Secretary',
//     contact_address: null,
//     is_authorized_signatory: false,
//     is_shareholder: false,
//     is_hiring_manager: false,
//     is_legal_representative: true,
//   },
//   {
//     id: 4,
//     company: 5,
//     contact_name: 'John Doe Junior',
//     contact_email: 'johndoe@example.com',
//     contact_phone_number: '+123456789',
//     contact_position: 'Director',
//     contact_address: '123 Main St, City, Country',
//     is_authorized_signatory: true,
//     is_shareholder: false,
//     is_hiring_manager: true,
//     is_legal_representative: false,
//   },
//   {
//     id: 5,
//     company: 5,
//     contact_name: 'Jane Smith Junior',
//     contact_email: 'janesmith@example.com',
//     contact_phone_number: '+987654321',
//     contact_position: 'Accountant',
//     contact_address: '456 Finance St, City, Country',
//     is_authorized_signatory: false,
//     is_shareholder: true,
//     is_hiring_manager: false,
//     is_legal_representative: true,
//   },
//   {
//     id: 6,
//     company: 5,
//     contact_name: 'Alice Johnson Junior',
//     contact_email: 'alicejohnson@example.com',
//     contact_phone_number: null,
//     contact_position: 'Secretary',
//     contact_address: null,
//     is_authorized_signatory: false,
//     is_shareholder: false,
//     is_hiring_manager: false,
//     is_legal_representative: true,
//   },
// ];

// export const fetchBusinessProfileContacts = createAsyncThunk<BusinessProfileContacts[], string>(
//   'businessProfile/fetchBusinessProfileContacts',
//   async (_, { rejectWithValue }) => {
//     try {
//       // TODO: Implement this
//       // const response = await NWClient.get<BusinessProfileContacts>('business-profile-contacts');
//       const response = mockBusinessProfileContacts;
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const fetchBusinessProfileContactById = createAsyncThunk<
  BusinessProfileContacts[],
  { id: number }
>('businessProfile/fetchBusinessProfileContactById', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await NWClient.get<BusinessProfileContacts[]>('business-profile-contacts', id);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const createBusinessProfileContact = createAsyncThunk<
  BusinessProfileContacts,
  { companyId: number; data: Omit<BusinessProfileContacts, 'id'> }
>(
  'businessProfile/createBusinessProfileContact',
  async ({ companyId, data }, { rejectWithValue }) => {
    try {
      const response = await NWClient.post<BusinessProfileContacts>(
        `business-profile-contacts/${companyId}`,
        data,
        false
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBusinessProfileContact = createAsyncThunk<
  BusinessProfileContacts,
  {
    companyId: number;
    id: number;
    data: BusinessProfileContacts;
  }
>(
  'businessProfile/updateBusinessProfileContact',
  async ({ companyId, id, data }, { rejectWithValue }) => {
    try {
      const response = await NWClient.put<BusinessProfileContacts>(
        `business-profile-contacts/${companyId}`,
        id,
        data,
        true
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteBusinessProfileContact = createAsyncThunk<
  number,
  { companyId: number; id: number }
>(
  'businessProfile/deleteBusinessProfileContact',
  async ({ companyId, id }, { rejectWithValue }) => {
    try {
      await NWClient.delete(`business-profile-contacts/${companyId}`, id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchBusinessProfileBankDetails = createAsyncThunk<
  BusinessProfileBankDetails[],
  { companyId: number }
>(
  'businessProfileBankDetails/fetchBusinessProfileBankDetails',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      const response = await NWClient.get<BusinessProfileBankDetails[]>(
        `business-profile-bank-details`,
        companyId
      );
      // const response = [
      //   {
      //     id: 1,
      //     company: 1,
      //     account_number: '123',
      //     account_name: 'Account name',
      //     swift_code: 'swift1234',
      //     country: 'Spain',
      //     currency: 'CAD',
      //   },
      // ];
      // const modifiedResponse = response.map((item) => ({
      //   ...item,
      //   currency: 'CAD',
      // }));
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBusinessProfileBankDetails = createAsyncThunk<
  BusinessProfileBankDetails,
  { companyId: number; id: number; data: BusinessProfileBankDetails }
>(
  'businessProfileBankDetails/updateBusinessProfileBankDetails',
  async ({ companyId, id, data }, { rejectWithValue }) => {
    try {
      const response = await NWClient.put<BusinessProfileBankDetails>(
        `business-profile-bank-details/${companyId}`,
        id,
        data,
        true
      );
      // const response = data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchBusinessProfileNotes = createAsyncThunk<
  BusinessProfileNotes[],
  { companyId: number }
>('businessProfileNotes/fetchBusinessProfileNotes', async ({ companyId }, { rejectWithValue }) => {
  try {
    const response = await NWClient.get<BusinessProfileNotes[]>(
      'business-profile-notes',
      companyId
    );
    // const response = [
    //   {
    //     id: 1,
    //     business_profile_id: 1,
    //     user_id: 1,
    //     note: 'Test note 123',
    //   },
    // ];
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const updateBusinessProfileNotes = createAsyncThunk<
  BusinessProfileNotes,
  { companyId: number; id: number; data: BusinessProfileNotes }
>(
  'businessProfileNotes/updateBusinessProfileNotes',
  async ({ companyId, id, data }, { rejectWithValue }) => {
    try {
      const response = await NWClient.put<BusinessProfileNotes>(
        `business-profile-notes/${companyId}`,
        id,
        data,
        true
      );
      // const response = data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteBusinessProfileNotes = createAsyncThunk<
  number,
  { companyId: number; id: number }
>('businessProfile/deleteBusinessProfileNotes', async ({ companyId, id }, { rejectWithValue }) => {
  try {
    await NWClient.delete(`business-profile-notes/${companyId}`, id);
    return id;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const createBusinessProfileNotes = createAsyncThunk<
  BusinessProfileNotes,
  { companyId: number; data: Omit<BusinessProfileNotes, 'id'> }
>(
  'businessProfile/createBusinessProfileNotes',
  async ({ companyId, data }, { rejectWithValue }) => {
    try {
      const response = await NWClient.post<BusinessProfileNotes>(
        `business-profile-notes/${companyId}`,
        data,
        false
      );

      /*const response: BusinessProfileNotes = {
        id: Math.floor(Math.random() * 1000),
        ...data,
        updated: '13/02/2023',
      };*/

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const businessProfileSlice = createSlice({
  name: 'businessProfile',
  initialState,
  reducers: {
    clearBusinessProfileNotes(state) {
      state.data.notes = []; // Reset notes to an empty array
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchBusinessProfile.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(fetchBusinessProfile.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   state.data['details'] = action.payload;
      // })
      // .addCase(fetchBusinessProfile.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.payload as string;
      // })
      .addCase(fetchBusinessProfileById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBusinessProfileById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data['details'] = action.payload;
      })
      .addCase(fetchBusinessProfileById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(createBusinessProfileEntry.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createBusinessProfileEntry.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data['details'] = action.payload;
      })
      .addCase(createBusinessProfileEntry.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(updateBusinessProfileEntry.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBusinessProfileEntry.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data['details'] = action.payload;
      })
      .addCase(updateBusinessProfileEntry.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(deleteBusinessProfileEntry.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteBusinessProfileEntry.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = initialState.data;
      })
      .addCase(deleteBusinessProfileEntry.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      // .addCase(fetchBusinessProfileContacts.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   if (state.data) {
      //     state.data.contacts = action.payload;
      //   }
      // })
      .addCase(fetchBusinessProfileContactById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.data) {
          state.data.contacts = action.payload;
        }
      })
      .addCase(createBusinessProfileContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.data) {
          state.data.contacts.push(action.payload);
        }
      })
      .addCase(createBusinessProfileContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(updateBusinessProfileContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.data) {
          const index = state.data.contacts.findIndex(
            (contact) => contact.id === action.payload.id
          );
          if (index !== -1) {
            state.data.contacts[index] = action.payload;
          }
        }
      })
      .addCase(deleteBusinessProfileContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.data) {
          state.data.contacts = state.data.contacts.filter(
            (contact) => contact.id !== action.payload
          );
        }
      })
      .addCase(deleteBusinessProfileContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      // .addCase(fetchBusinessProfileSocials.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   if (state.data) {
      //     state.data.socials = action.payload;
      //   }
      // })
      // .addCase(fetchBusinessProfileSocials.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.payload as string;
      // })
      // .addCase(updateBusinessProfileSocials.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   if (state.data) {
      //     state.data.socials = action.payload as Social[]; // TODO: remove force typing
      //   }
      // })
      // .addCase(updateBusinessProfileSocials.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.payload as string;
      // })
      .addCase(fetchBusinessProfileBankDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBusinessProfileBankDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.bankDetails = action.payload;
      })
      .addCase(fetchBusinessProfileBankDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(updateBusinessProfileBankDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.data) {
          const index = state.data.bankDetails.findIndex((bank) => bank.id === action.payload.id);
          if (index !== -1) {
            state.data.bankDetails[index] = action.payload;
          }
        }
      })
      .addCase(updateBusinessProfileBankDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(fetchBusinessProfileNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.notes = action.payload;
      })
      .addCase(fetchBusinessProfileNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(updateBusinessProfileNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.data) {
          const index = state.data.notes.findIndex((bank) => bank.id === action.payload.id);
          if (index !== -1) {
            state.data.notes[index] = action.payload;
          }
        }
      })
      .addCase(updateBusinessProfileNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(deleteBusinessProfileNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.data) {
          state.data.notes = state.data.notes.filter((note) => note.id !== action.payload);
        }
      })
      .addCase(deleteBusinessProfileNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(createBusinessProfileNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.data) {
          state.data.notes.push(action.payload);
        }
      })
      .addCase(createBusinessProfileNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

fetchBusinessProfileNotes;

export const { clearBusinessProfileNotes } = businessProfileSlice.actions;
export default businessProfileSlice.reducer;

export const selectBusinessProfile = (state: RootState) => state.businessProfile.data;
export const selectBusinessProfileBankDetails = (state: RootState) =>
  state.businessProfile.data?.bankDetails;
export const selectBusinessProfileContacts = (state: RootState) =>
  state.businessProfile.data?.contacts;
export const selectBusinessProfileDetails = (state: RootState) =>
  state.businessProfile.data?.details;
// export const selectBusinessProfileSocials = (state: RootState) =>
//   state.businessProfile.data?.socials;
export const selectBusinessProfileNotes = (state: RootState) => state.businessProfile.data?.notes;
export const selectBusinessProfileStatus = (state: RootState) => state.businessProfile.status;
export const selectBusinessProfileError = (state: RootState) => state.businessProfile.error;
