import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import styles from './CustomStepper.module.scss';
import Line from '../../../static/assets/svg/line.svg';
import DashedLine from '../../../static/assets/svg/dashed-line.svg';
import { Icon } from '@mui/material';

interface CustomStepperProps {
  steps: string[];
  activeStep: number;
  completed?: { [k: number]: boolean };
  onStepClick?: (step: number) => void;
}

export default function CustomStepper({
  steps,
  activeStep,
  completed = {},
  onStepClick,
}: CustomStepperProps) {
  const handleStep = (step: number) => () => {
    if (onStepClick) {
      onStepClick(step);
    }
  };

  return (
    <Box className={styles.stepperContainer}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        className={styles.stepper}
        connector={
          <Box sx={{ mr: 24 }}>
            <DashedLine />
          </Box>
        }
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]} className={styles.step}>
            <StepButton color='inherit' onClick={handleStep(index)} className={styles.stepButton}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
