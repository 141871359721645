import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import styles from './CreateDocument.module.scss';
import PlaceHolderDocumentItem from '../../../../common/placeholder-document-item/PlaceHolderDocumentItem';

const CreateDocument: React.FC = () => {
  return (
    <Box className={styles.container}>
      <Typography variant='h6' className={styles.header}>
        Acceptable use policy
      </Typography>

      <Box className={styles.breadcrumbs}>
        <Typography component='span'>Employment</Typography>
        <Typography component='span'>/</Typography>
        <Typography component='span'>Onboarding</Typography>
        <Typography component='span'>/</Typography>
        <Typography component='span'>Agreement or contract</Typography>
      </Box>
      <List className={styles.list}>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Employer details'}
            value={'Company XYZ'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Employer contract'}
            value={'Acceptable use policy'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Choose profile'}
            value={'Employment'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Profile name'}
            value={'Acceptable use policy'}
            placeholder={'Placeholder'}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default CreateDocument;
