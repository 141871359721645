import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface NoteInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

export default function NoteInput({ value, onChange, disabled }: NoteInputProps) {
  const { t } = useTranslation();

  return (
    <TextField
      id='note-input'
      label={t('formInputs.noteInputLabel')}
      variant='outlined'
      type='text'
      fullWidth
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}
