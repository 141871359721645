import React, { ChangeEvent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { addCompany } from '../../slices/companies/companiesSlice';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { toast } from 'react-toastify';
import { ProgressOverlay } from '../progress-overlay/ProgressOverlay';
import { FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { NWClient } from '../../client/NWClient';
import { CountryItem } from './types';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectCountriesInfo } from '../../slices/countries/countriesSlice';

const CompanyCreateForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>('');
  const [country, setCountry] = useState<{ value: string; title: string } | null>(null);
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState<string>('');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState<string>('');
  const countries = useAppSelector(selectCountriesInfo);
  const countriesOptions = [...countries]
    .filter((el) => el.code !== 'GB')
    .map((el) => ({ value: el.name, title: el.name }));
  const countriesLoading = useAppSelector((state) => state.countriesInfo.status);

  const createCompany = () => {
    setLoading(true);
    const data = {
      name: businessName,
      phone_number: businessPhoneNumber.trim().replace(/\s/g, ''),
      business_abn_acn: businessRegistrationNumber.trim().replace(/\s/g, ''),
      country: country ? country.value : null,
      postal_country: country ? country.value : null,
      physical_country: country ? country.value : null,
    };
    dispatch(addCompany({ data }))
      .unwrap()
      .then(() => {
        toast.success(t('messages.companyCreated'));
      })
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {countriesLoading === 'loading' ? (
        <ProgressOverlay className='position-absolute' />
      ) : (
        <>
          <Box className='company-create-form'>
            <Box className='message-container'>
              <p>{t('messages.finishCompanySetUp')}</p>
            </Box>
            <Box component='form' noValidate autoComplete='off'>
              <TextField
                label={t('labels.businessName')}
                onChange={(event) => setBusinessName(event.target.value)}
                variant='outlined'
                required={true}
              />
              <FormControl>
                <Autocomplete
                  id='country'
                  openOnFocus={true}
                  options={countriesOptions}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  getOptionLabel={(option) => option.title}
                  value={country}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>,
                    newValue: {
                      title: string;
                      value: string;
                    } | null
                  ) => {
                    setCountry(newValue);
                  }}
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li key={option.value} {...optionProps}>
                        {option.title}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={true}
                      variant='standard'
                      label={t('labels.country')}
                    />
                  )}
                />
              </FormControl>
              <TextField
                label={t('labels.businessPhoneNumber')}
                onChange={(event) => setBusinessPhoneNumber(event.target.value)}
                variant='outlined'
              />
              <TextField
                label={t('labels.businessRegistrationNumber')}
                onChange={(event) => setBusinessRegistrationNumber(event.target.value)}
                variant='outlined'
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                  width: '100%',
                  marginTop: 'auto',
                }}
              >
                <Button
                  variant='contained'
                  className='btn-default btn-action-basic'
                  type='button'
                  disabled={!businessName && !country}
                  onClick={() => createCompany()}
                >
                  {t('buttons.submit')}
                </Button>
              </Box>
            </Box>
          </Box>
          {loading && <ProgressOverlay />}
        </>
      )}
    </>
  );
};

export default CompanyCreateForm;
