import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import styles from './PlaceHolderDocumentItem.module.scss';

interface CustomTextFieldProps {
  value: string;
  placeholder: string;
  label: string;
}

const PlaceHolderDocumentItem: React.FC<CustomTextFieldProps> = ({ value, placeholder, label }) => {
  return (
    <TextField
      fullWidth
      variant='outlined'
      size='medium'
      label={label}
      value={value}
      placeholder={placeholder}
    />
  );
};

export default PlaceHolderDocumentItem;
