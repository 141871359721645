import React from 'react';
import { Box, Typography, Grid, ListItem, List } from '@mui/material';
import styles from './DetailsDocument.module.scss';
import PlaceHolderDocumentItem from '../../../../common/placeholder-document-item/PlaceHolderDocumentItem';

const DetailsDocument: React.FC = () => {
  return (
    <Box className={styles.container}>
      <List className={styles.list}>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
        <ListItem className={styles.listItem}>
          <PlaceHolderDocumentItem
            label={'Placeholder'}
            value={'Value'}
            placeholder={'Placeholder'}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default DetailsDocument;
