import { useEffect } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { fetchCountries } from '../../slices/countries/countriesSlice';

export const useCountriesInfo = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchCountries());
  }, []);
};
