import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import DocumentList from '../../../common/document-list/DocumentList';
import FilterDrawer from '../../../common/filter-drawer/FilterDrawer';
import { Company } from '../../../models/company.model';
import { fetchDocuments } from '../../../slices/documents/documentsSlice';
import { selectAllDocumentContracts } from '../../../slices/document-contracts/documentContractsSlice';
import { selectAllDocuments } from '../../../slices/documents/documentsSlice';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { fetchDocumentContracts } from '../../../slices/document-contracts/documentContractsSlice';
import { Employee } from '../../../models/employee.model';
import DocumentListVault from '../../document-vault/components/document-list-vault/DocumentListVault';
import { useAppSelector } from '../../../common/hooks/useAppSelector';

const documents: any[] = [];
const filterSections = [
  {
    title: 'Profile',
    type: 'checkbox' as const,
    options: [
      { label: 'Employment', value: 'employment' },
      { label: 'Business', value: 'business' },
      { label: 'Customers', value: 'customers' },
    ],
  },
  {
    title: 'Document type',
    type: 'checkbox' as const,
    options: [
      { label: 'Contract', value: 'contract' },
      { label: 'Invoice', value: 'invoice' },
      { label: 'Certificate', value: 'certificate' },
      { label: 'Letter', value: 'letter' },
    ],
  },
  {
    title: 'Workflow',
    type: 'checkbox' as const,
    options: [
      {
        label: 'Employment',
        value: 'employment',
        subOptions: [
          { label: 'Recruitment', value: 'recruitment' },
          { label: 'Induction', value: 'induction' },
          { label: 'Onboarding', value: 'onboarding' },
          { label: 'Employee management', value: 'employee_management' },
          { label: 'Termination', value: 'termination' },
        ],
      },
    ],
  },
];
interface DocumentsTabsProps {
  documents: any[];
  key: string;
}

const DocumentsTabs: React.FC<DocumentsTabsProps> = ({ documents }) => {
  const sortByDate = (a: any, b: any) => {
    return Number(new Date(b.created)) - Number(new Date(a.created));
  };
  const dispatch = useAppDispatch();
  const documentContracts = useAppSelector(selectAllDocumentContracts);
  const documentUploads = useAppSelector(selectAllDocuments);

  // useEffect(() => {
  //   const fetchAllDocuments = async () => {
  //     // For every member, dispatch both actions and wait for both results
  //     const results = await Promise.all(
  //       company.members.map(async (member) => {
  //         console.log('Member:', member);
  //         const [docsAction, contractsAction] = await Promise.all([
  //           dispatch(fetchDocuments({ data: { user: member.id, category: 'employment' } })),
  //           dispatch(fetchDocumentContracts({ data: { user: member.id, category: 'employment' } })),
  //         ]);
  //         // Assuming the payloads contain the resulting documents/objects
  //         return [
  //           ...(Array.isArray(docsAction.payload) ? docsAction.payload : []),
  //           ...(Array.isArray(contractsAction.payload) ? contractsAction.payload : []),
  //         ];
  //       })
  //     );
  //     const filteredDocuments = results
  //       .flat()
  //       .filter((doc) => doc.employees?.includes(employee.id));
  //     setDocuments(filteredDocuments);
  //   };

  //   fetchAllDocuments();
  // }, [company.members, dispatch, documentContracts, documentUploads]);

  const handleApplyFilters = (filters: any) => {
    console.log('Applied filters:', filters);
    // Implement filter logic here
  };

  return (
    <Grid container wrap='nowrap'>
      <Grid item xs={12}>
        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <FilterDrawer sections={filterSections} onApplyFilters={handleApplyFilters} />
        </Box> */}
        <DocumentListVault documents={documents} />
      </Grid>
    </Grid>
  );
};

export default DocumentsTabs;
