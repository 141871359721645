import { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import {
  fetchBusinessProfileById,
  selectBusinessProfileDetails,
  updateBusinessProfileEntry,
  BusinessProfileDetails,
} from '../../slices/business-profile/businessProfileSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import { NWClient } from '../../client/NWClient';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';

export function useDetailsCardForm(companyId: number) {
  const dispatch = useAppDispatch();
  const businessProfileDetails = useAppSelector(selectBusinessProfileDetails);
  const company = useAppSelector(selectAllCompanies)[0];
  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [showAddSocialModal, setAddSocialShowModal] = useState(false);
  const [country, setCountry] = useState<{ value: string; title: string }>(null);
  const formRef = useRef<HTMLDivElement | null>(null);
  const [formData, setFormData] = useState<BusinessProfileDetails>({
    id: 0,
    company: companyId ? companyId : 1,
    business_email_address: 'randombusmail@gmail.com',
    business_phone_number: null,
    registration_number: null,
    registration_number_2_or_tax_number: null,
    //business_country: null,
    registered_address: null,
    physical_address: null,
    postal_address: null,
  });

  useEffect(() => {
    if (company) {
      setCompanyName(company.name);
      setCountry({ value: company.physical_country, title: company.physical_country });
    }
  }, [company]);
  useEffect(() => {
    dispatch(fetchBusinessProfileById({ id: companyId }));
  }, []);

  useEffect(() => {
    if (businessProfileDetails) {
      setFormData({
        ...businessProfileDetails,
      });
      if (businessProfileDetails.business_country) {
        setCountry({
          value: businessProfileDetails.business_country,
          title: businessProfileDetails.business_country,
        });
      }
    }
  }, [businessProfileDetails]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isEdited && formRef.current && !formRef.current.contains(event.target as Node)) {
        if (event.target instanceof HTMLElement) {
          const ignoredElements = ['MuiTab-root'];
          const classList = Array.from(event.target.classList);
          if (ignoredElements.some((item) => classList.includes(item))) {
            setTimeout(() => {
              setShowModal(true);
            }, 0);
          }
        }
      }
    };
    if (isEdited) document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdited]);

  const handleChange =
    <K extends keyof BusinessProfileDetails>(field: K) =>
    (value: BusinessProfileDetails[K]) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

  const handleCompanyNameChange = (value: string) => {
    setCompanyName(value);
  };

  const saveChanges = () => {
    if (!businessProfileDetails?.company) return;
    formData.business_country = country ? country.value : '';
    dispatch(
      updateBusinessProfileEntry({
        id: businessProfileDetails.company,
        data: formData,
      })
    );
    NWClient.patch(`company/${companyId}/`, null, {
      name: companyName,
      physical_country: country.value,
      postal_country: country.value,
    });

    setIsEdited(false);
    setShowModal(false);
  };

  const saveChangesSocialForm = () => {
    console.log('Saved social links');
    // dispatch(updateBusinessProfileSocials());
    toogleEditingSocialModal();

    //       dispatch(
    // // TODO: call slice to update soscials
    //       );
  };

  const toggleEditing = () => {
    setIsEdited((prev) => !prev);
  };

  const toogleEditingSocialModal = () => {
    setAddSocialShowModal((prev) => !prev);
  };

  const confirmExit = () => {
    setIsEdited(false);
    setShowModal(false);
  };

  return {
    formData,
    handleChange,
    handleCompanyNameChange,
    saveChanges,
    isEdited,
    setIsEdited,
    toggleEditing,
    formRef,
    showModal,
    setShowModal,
    confirmExit,
    showAddSocialModal,
    setAddSocialShowModal,
    toogleEditingSocialModal,
    saveChangesSocialForm,
    country,
    companyName,
    setCountry,
  };
}
